div.cmp_customsecurity-mdl_edit-container .modal-header-title {
    flex-grow: 1;
}

div.cmp_customsecurity-mdl_edit-container .select_field {
    width: 50%;
}

div.cmp_customsecurity-mdl_edit-container div.or_text {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 5px;
    color: #cfd3d5;
}

div.cmp_customsecurity-mdl_edit-container div.access_checkboxes {
    display: flex;
    flex-direction: column;
}

div.cmp_customsecurity-mdl_edit-container div.access_checkboxes .checkbox {
    padding-bottom: 3px;
}

div.cmp_customsecurity-mdl_edit-container div.message.warning {
    margin: 8px 0 0 0;
}

canvas {
    border: 1px solid #ebebeb;
}

.tiny-button {
    float: right;
    font-size: 8pt;
}

#notes {
    width: 502px;
    max-width: 502px;
    font-family: "Roboto", sans-serif !important;
    padding-top: 9px !important;
    padding-bottom: 9px !important;
    font-size: 13px !important;
    line-height: 18px !important;
    font-weight: 500 !important;
    color: #4b4f4e !important;
    border-color: #ebebeb !important;
    box-shadow: 0 0 0 1000px #fbfbfb inset!important;
    border-radius: 3px !important;
}

#mdl_acknowledge_document label[for="notes"]:after {
    margin-left: 0.3em;
    content: "*";
    font-weight: bold;
    color: #8b0000;
    font-style: normal;
}