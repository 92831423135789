#mdl_addcredential {
    max-width: 525px;
}

#mdl_addcredential_container .modal-header-title {
    flex-grow: 1;
}

#mdl_addcredential_container div.fields:first-of-type {
    margin-top: 5px;
}

#mdl_addcredential_container div.fields div.field {
    width: 100%;
}