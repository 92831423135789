
#tab_org_overview_dailycheck .content-filter {
    padding-top: 24px;
}

#tab_org_overview_dailycheck .content-card-content {
    padding: 16px;
}

#tab_org_overview_dailycheck .subtitle {
    font-size: 11px;
    font-weight: 500;
    color: #c6cecc;
    padding-bottom: 8px;
}

#tab_org_overview_dailycheck .checkresultswrapper {
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
}

#tab_org_overview_dailycheck .checkresult {
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    padding: 8px;
    margin-right: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 64px;
    margin-bottom: 4px;
}

#tab_org_overview_dailycheck .checkresult_text {
    padding-bottom: 8px;
    font-weight: 500;
    text-align: center;
}

#tab_org_overview_dailycheck .checkresult_icon {
    height: 32px;
    width: 32px;
}

#tab_org_overview_dailycheck .checkresult_icon img {
    height: 100%;
    width: 100%;
}

#tab_org_overview_dailycheck .tbl_icon {
    margin-right: 8px;
    margin-top: -1px;
}

#tab_org_overview_dailycheck .tbl_dailycheck {
    padding-left: 16px !important;
}

#tab_org_overview_dailycheck .tbl_icon {
    padding-top: 1px !important;
    margin-left: 7px;
}

/*  media queries --------------------------------------------------------------------------------------------------- */

@media only screen and (max-width: 1500px) {

    #tab_org_overview_dailycheck .td_phone {
        display: none;
    }
}

@media only screen and (max-width: 1370px) {

    #tab_org_overview_dailycheck .td_organization {
        display: none;
    }
}

@media only screen and (max-width: 1165px) {

    #tab_org_overview_dailycheck .td_businessunit {
        display: none;
    }
}

@media only screen and (max-width: 875px) {

    #tab_org_overview_dailycheck .td_location {
        display: none;
    }
}