.mdl_cmp_qrcode {
    cursor: pointer;
    margin-bottom: 16px;
}

#mdl_cmp_qrcode .qrcode-instruction {
    font-size: 18px;
    font-weight: 400;
    color: #374151;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 125px;
    padding-right: 125px;
    margin-bottom: 16px;
}

#mdl_cmp_qrcode {
    width: 550px;
}

#mdl_cmp_qrcode .modal-content {
    text-align: center;
    padding: 8px;
}

#mdl_cmp_qrcode .modal-header-title {
    font-size: 20px;
    font-weight: 500;
    color: #36484E;
    line-height: 23.44px;
    padding: 8px;
    gap: 8px;
}

#mdl_cmp_qrcode .qraddress {
    font-size: 13px;
    font-weight: 400;
    color: #4b4f4e;
    line-height: 16px;
}

#mdl_cmp_qrcode .modal-footer {
    /*border-top: 1px solid #E8E9E9;*/
    height: 56px;
    background-color: #ffffff !important;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    background-image: none !important;
  }