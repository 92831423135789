#org-dailychecks_mdl_updatequestionniare #tab_details {
    padding: 40px 16px 32px 16px;
}

#org-dailychecks_mdl_updatequestionniare #tab_details .default {
    margin-bottom: 16px !important;
}

#org-dailychecks_mdl_updatequestionniare #tab_details .failwarningtext {
    width: 100%;
    margin-top: 16px;
}

#org-dailychecks_mdl_updatequestionniare #tab_details .stat_passtext {
    width: 100%;
    margin-top: 16px;
}

#org-dailychecks_mdl_updatequestionniare #tab_details .stat_failtext {
    width: 100%;
    margin-top: 16px;
}

#org-dailychecks_mdl_updatequestionniare #tab_details .guestconsenttext {
    width: 100%;
    margin-top: 16px;
}

#reqfielderror {
    display: flex;
    flex-direction: row;
}