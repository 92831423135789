
.selectfile {
    width: 100%;
}

.cmp_fileupload .message {
    margin-top: 20px;
    height: 38px;
}

.cmp_fileupload .ui.progress {
    margin-top: 20px !important;
    height: 38px;
}

#invite_error{
    display: flex;
}