
#crd_indv_forms_forms .content-card-header {
    padding-right: 8px;
}

#crd_indv_forms_forms .content-filter-dropdown {
    padding: 16px 0 16px 0;
    margin-bottom: 8px;
    min-width: 300px;    
  }
#crd_indv_forms_forms .tbl_credentialicon div {
    display: flex;
    margin-top: 1px;
}

#crd_indv_forms_forms .td_icon_center {
    display: flex;
    justify-content: center;
    height: 31px;
}

#crd_indv_forms_forms .td_attachment img {
    width: 18px;
    height: 18px;
    margin: -1px 0 -3px 0;
}

#crd_indv_forms_forms .td_attachment {
    width: 34px;
}

#crd_indv_forms_forms .td_attachment img {
    width: 18px;
    height: 18px;
    margin: -1px;
}

#crd_indv_forms_forms .td_formicon img {
    width: 18px;
    height: 18px;
}

#crd_indv_forms_forms .td_formstatus {
    display: flex !important;
}

#crd_indv_forms_forms .td_formicon {
    margin-right: 5px;
}

#mdl_info {
    width: 400px;
}

#mdl_info .success_icon{
    width: 30px !important;
}

#mdl_info .success_icon img{
    width: 30px;
}

/*  media queries --------------------------------------------------------------------------------------------------- */

@media only screen and (max-width: 1215px) {
    #crd_indv_forms_forms .td_lockedon {
        display: none !important;
    }
}

@media only screen and (max-width: 900px) {
    #crd_indv_forms_forms .td_lockedby {
        display: none !important;
    }
}

@media only screen and (max-width: 767px) {

    #crd_indv_forms_forms .tbl_row {
        margin-bottom: 0rem !important;
        margin-top: 0rem !important;
        padding-bottom: 11.5rem;
        border-top: 1px solid;
    }

    #crd_indv_forms_forms .td_overflow {
        max-width: 30rem;
    }

    #crd_indv_forms_forms .tablewrapper {
        margin-top: 3rem;
        border-bottom: 1px solid;
    }

    #crd_indv_forms_forms .tbl_forms_header {
        display: none;
    }

    #crd_indv_forms_forms .td_formname {
        color: #29719a;
        text-decoration: underline;
    }

    #crd_indv_forms_forms .content-filter {
        flex-direction: column;
    }

}

@media only screen and (max-width: 550px) {
    #crd_indv_forms_forms .td_overflow {
        max-width: 24rem;
    }

    #mdl_info {
        width: 300px;
    }
}

@media only screen and (max-width: 350px) {
    #crd_indv_forms_forms .td_overflow {
        max-width: 20rem;
    }
}