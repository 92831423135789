
#crd_indv_overview_details {
    width: 400px;
    margin-right: 8px;
    margin-bottom: 4px;
}

#crd_indv_overview_details .content-card-content {
    padding: 24px 24px 32px 24px;
}

#crd_indv_overview_details .detail-spacer {
    height: 4px;
}

#crd_indv_overview_details .detail-item {
    display: grid;
    grid-template-columns: 110px auto;
}

#crd_indv_overview_details .detail-item-label {
    font-size: 11px;
    font-weight: 500;
    color: #cfd3d5;
}

@media only screen and (max-width: 700px) {

    #crd_indv_overview_details {
        width: 100%;
        margin-right: 0;
    }

}