#tab_indv_wallet_requirements .mobile_tab_name {
    display: none;
}

#tab_indv_wallet_requirements .tbl_requirementicon div {
    display: flex;
}

#tab_indv_wallet_requirements .filler {
    padding-top: 18px;
}


#tab_indv_wallet_requirements .td_icon_center {
    display: flex;
    justify-content: center;
    height: 31px;
}

#tab_indv_wallet_requirements .td_attachment img {
    width: 18px;
    height: 18px;
    margin: -1px 0 -3px 0;
}

#tab_indv_wallet_requirements .icon_history {
    width: 18px;
    height: 18px;
}

#tab_indv_wallet_requirements .td_attachment {
    width: 34px;
}

#tab_indv_wallet_requirements .td_status {
    display: flex;
}

#tab_indv_wallet_requirements .td_statusicon {
    margin-right: 5px;
}

#tab_indv_wallet_requirements .td_statusicon img {
    width: 18px;
    height: 18px;
}

/*  media queries --------------------------------------------------------------------------------------------------- */

@media only screen and (max-width: 1200px) {

    #tab_indv_wallet_requirements .td_issuedby {
        display: none;
    }
}

@media only screen and (max-width: 1150px) {

    #tab_indv_wallet_requirements .td_expires {
        display: none;
    }
}

@media only screen and (max-width: 1060px) {

    #tab_indv_wallet_requirements .td_credential {
        display: none;
    }
}

@media only screen and (max-width: 800px) {

    #tab_indv_wallet_requirements .td_requirement {
        max-width: 11rem;
    }
}

@media only screen and (max-width: 700px) {

    #tab_indv_wallet_requirements .td_requirement {
        max-width: 9rem;
    }

}

@media only screen and (max-width: 500px) {

    #tab_indv_wallet_requirements .content-card-gridcontent{
        max-width: 20rem;
    }
    
    #tab_indv_wallet_requirements .content-filter {
        display: flex;
        flex-direction: column;
        padding: 5%;
    }

    #tab_indv_wallet_requirements .mobile_tab_name {
        padding-top: 5%;
        padding-bottom: 5%;
        display: block;
        text-align: center;
    }

    #tab_indv_wallet_requirements .item-pagination {
        align-content: center;
    }


}

@media only screen and (max-width: 470px) {

    #tab_indv_wallet_requirements .td_organization{
        display:none;
    }
}

@media only screen and (max-width: 370px) {

    #tab_indv_wallet_requirements .td_assignedfrom{
        display:none;
    }
}