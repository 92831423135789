#dailycheck_mdl_preview .modal-header-close {
    margin-left: auto;
}

#dailycheck_mdl_preview .ui.slider.checkbox label:before {
    background-color: #000000 !important;
}

#dailycheck_mdl_preview .preview-toolbar {
    display: flex;
}
#dailycheck_mdl_preview .preview-toolbar label {
    width: auto;
    padding: 0 8px;
    cursor: pointer;
}

#dailycheck_mdl_preview .preview-results {
    display: flex;
    padding-top: 16px;
}
#dailycheck_mdl_preview .preview-results label {
    width: auto;
    padding: 0 8px;
}
#dailycheck_mdl_preview .preview-results .preview-results-fail {
    font-weight: bold;
    color: #e0454f;
}
#dailycheck_mdl_preview .preview-results .preview-results-pass {
    font-weight: bold;
    color: #69b536;
}
#dailycheck_mdl_preview .preview-results .preview-results-incomplete {
    color: #4b4f4e;
}
#dailycheck_mdl_preview .preview-results .preview-results-quarantinedays {
    color: #4b4f4e;
    margin-left: 8px;
}