@media only screen and (max-width: 1000px) {
  
    #indv_dailycheck_content .content-sidemenu-wrapper {
      display: flex;
      flex-direction: column !important;
    }

    #indv_dailycheck_content .content-sidemenu {
        max-height: 200px;
        width: 100% ;
    }

  
  }