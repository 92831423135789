.profile_section_body .td_loc_icon_center {

    justify-content: center;
    width: 120px !important;
}

.profile_section_body .td_loc_icon_center .profile_onsite_status {

    display: flex;
    justify-content: center;
}

@media only screen and (max-width: 420px) {

    .profile_section_body .td_loc_icon_center {
        width: 100px !important;
    }

}

