
.crd_createaccount .logo {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
}

.crd_createaccount .logo img {
    width: 75%;
    height: 75%;
}

.crd_createaccount .locationlabel {
    text-align: center;
    font-size: 11px;
    font-weight: 500;
    color: #cfd3d5;
    margin: 0 0 3px 0;
}

.crd_createaccount .location {
    font-size: 16px;
    font-weight: 700;
    color: #49595f;
    text-align: center;
    margin-bottom: 24px;
}

.crd_createaccount .firstname {
    width: 100%;
}

.crd_createaccount .lastname {
    width: 100%;
}

.crd_createaccount .email {
    width: 100%;
}

.crd_createaccount .phone {
    width: 100%;
}

.crd_createaccount .company {
    width: 100%;
    margin-bottom: 24px;
}

.crd_createaccount .company div {
    width: 100%;
}

.crd_createaccount .ui.search>.results>.message {
    border: none !important;
    margin-bottom: 0px !important;
}

.crd_createaccount .subcontractor {
    width: 100%;
    flex-direction: column !important;
}

.crd_createaccount .subcontractor .fields>.field {
    padding: 0 !important;
}

.crd_createaccount #btn_submitaccount {
    /*margin-top: 24px !important;*/
    width: 100%;
}

.crd_createaccount .ui.button.btn_primary {
    background-color: #005687;
    color: #ffffff;
    border-color: #005687;
    font-weight: 700;
    opacity: 0.5;
    cursor: default;
}

.crd_createaccount .btn_backtologin {
    color: #005687;
    font-weight: 500;
    font-style: italic;
    cursor: pointer;
    padding: 16px 0px 0px 0px;
}

.crd_createaccount .ui.primary.button,
.crd_createaccount .ui.primary.buttons .button,
.crd_createaccount .ui.loading.loading.loading.loading.loading.loading.button {
    background-color: #005687;
}

.crd_createaccount .ui.button.btn_primary.btn_active:hover {
    background-color: #005687 !important;
}