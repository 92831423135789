  
  /*  media queries --------------------------------------------------------------------------------------------------- */

  @media only screen and (max-width: 700px) {
    .legend-footer {
        flex-direction: column;
    }

    .legend-item {
        margin-bottom: 15px;
        padding-left: 3%;
    }
}