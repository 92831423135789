#crd_newpassword .toc {
    padding-top: 24px;
    text-align: center;
    font-size: 12px;
    line-height: 16px;
    color: #63666a;
}

#crd_newpassword .toc a {
    color: #005687;
}

#crd_newpassword .ui.primary.button,
#crd_newpassword .ui.primary.buttons .button,
#crd_newpassword .ui.loading.loading.loading.loading.loading.loading.button {
    background-color: #005687;
}

#crd_newpassword .ui.button.btn_primary.btn_active:hover {
    background-color: #005687 !important;
}