.profile_section_body .tbl_credentialicon div {
    display: flex;
    margin-top: 1px;
}

.profile_section_body .td_loc_icon_center {
    width: 120px !important;
}

.profile_section_body .td_attachment img {
    width: 18px;
    height: 18px;
    margin: -1px 0 -3px 0;
}

.profile_section_body .icon_history {
    width: 18px;
    height: 18px;
}

.profile_section_body .td_attachment {
    width: 34px;
}

.profile_section_body .view_certificate{
    pointer-events: auto;
}

.profile_section_body .mobile_view_display {
    display: none;
}

/*  media queries --------------------------------------------------------------------------------------------------- */

@media only screen and (max-width: 1200px) {

}

@media only screen and (max-width: 1150px) {

    .profile_section_body .td_expires {
        display: none;
    }
}

@media only screen and (max-width: 1060px) {

    .profile_section_body .td_issued {
        display: none;
    }

    .profile_section_body .td_daysleft {
        display: none;
    }
}

@media only screen and (max-width: 700px) {

    .profile_section_body .td_verification {
        display: none;
    }

    .profile_section_body .cmp_confirmation_status_txt {
        display: none;
    }

    .profile_section_body .mobile_view_display {
        display: table-cell;
    }

    .profile_section_body .mobile_view_hide {
        display: none;
    }

    .profile_section_body .cmp_confirmation {
        display: block !important;
    }

}

@media only screen and (max-width: 530px) {

    .profile_section_body .td_issuedby {
        display: none;
    }

}

@media only screen and (max-width: 420px) {

    .profile_section_body .td_loc_icon_center {
        width: 100px !important;
    }

}