#mdl_share_vc {
    width: 55rem;
 }

#mdl_share_vc .modal-header-title {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
}

#mdl_share_vc .modal-header-img {
    height: 2rem;
    width: 2rem;
}

#mdl_share_vc .modal-header-img img {
    max-width: 100%;
}

#mdl_share_vc .modal-header-text {
    margin-top: 5px;
    margin-left: 5px;
}

#mdl_share_vc .modal-content {
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 12rem;

}

#mdl_share_vc .detail-item {
    display: flex;
    height: 2rem;
    line-height: 2rem;
}

#mdl_share_vc .detail-item-label {
    font-size: 0.9rem;
    width: 14rem;
}

#mdl_share_vc .detail-item-text {
    font-size: 1rem;
    width: 37.5rem;
}