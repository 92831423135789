#tab_orgcredentials .btn_primary {
    margin-right: 10px !important;
}

#tab_orgcredentials .td_attachment {
    width: 34px;
}

#tab_orgcredentials .td_attachment img {
    width: 18px;
    height: 18px;
    margin: -1px 0 -3px 0;
}