#indv_forms_mdl_assignment {
    width: 800px;
    max-width: 85%;
}

#indv_forms_mdl_assignment .modal-header-close {
    margin-left: auto;
 }

#indv_forms_mdl_assignment .modal-content {
    padding: 16px 16px 32px 16px;
}

#indv_forms_mdl_assignment  .btn_secondary {
    opacity: 0.5;
    cursor: default;
}

#indv_forms_mdl_assignment  .btn_secondary.btn_active {
    opacity: 1;
    cursor: pointer;
}
/*  media queries --------------------------------------------------------------------------------------------------- */

@media only screen and (max-width: 767px) {

    #indv_forms_mdl_assignment .tbl_row {
        margin-bottom: 0rem !important;
        margin-top: 0rem !important;
        padding-bottom: 13rem;
        border-top: 1px solid;
    }

    #indv_forms_mdl_assignment .tablewrapper {
        margin-top: 3rem;
        border-bottom: 1px solid;
    }

    #indv_forms_mdl_assignment .table_header {
        display: none;
    }

}