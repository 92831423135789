/*  media queries --------------------------------------------------------------------------------------------------- */

@media only screen and (max-width: 1150px) {


}

@media only screen and (max-width: 1060px) {


}

@media only screen and (max-width: 700px) {

    .public_location_section_body .td_ack {
        display: none;
    }

}

@media only screen and (max-width: 530px) {


}