#org-locations_mdl_updatelocation #tab_forms {

}


#org-locations_mdl_updatelocation #tab_forms .tbl_credentialicon div {
    display: flex;
    margin-top: 1px;
}

#org-locations_mdl_updatelocation #tab_forms .td_icon_center {
    display: flex;
    justify-content: center;
    height: 31px;
}

#org-locations_mdl_updatelocation #tab_forms .td_attachment img {
    width: 18px;
    height: 18px;
    margin: -1px 0 -3px 0;
}

#org-locations_mdl_updatelocation #tab_forms .td_attachment {
    width: 34px;
}

#org-locations_mdl_updatelocation #tab_forms .td_attachment img {
    width: 18px;
    height: 18px;
    margin: -1px;
}

#org-locations_mdl_updatelocation .td_formicon img {
    width: 18px;
    height: 18px;
}

#org-locations_mdl_updatelocation .td_formstatus {
    display: flex !important;
}

#org-locations_mdl_updatelocation .td_formicon {
    margin-right: 5px;
}
