#cmp_success_container .modal-header-title {
    flex-grow: 1;
}

#cmp_success_container .modal-content {
    padding: 0;
}

#cmp_success_container div.success_container {
    display: flex;
    flex-direction: row;
}

#cmp_success_container div.success_icon {
    width: 56px;
    height: 56px;
    margin-right: 16px;
}

#mdl_indv_updaterequirement div.success_icon img {
    width: 100%;
    height: 100%;
}

#cmp_success_container div.success_text {
    color: #69b536;
    font-weight: 600;
}

#cmp_success_container div.transaction_id_text {
    padding: 16px 0 8px 0 !important;
    color: #aaa9aa;
}

#cmp_success_container div.fail_text {
    color: #db2430;
    font-weight: bold;
}

#cmp_success_container div.success_container > div:last-child > div {
    padding-bottom: 5px;
}

#cmp_success_container div.success_container > div:last-child > div:last-child {
    padding-bottom: 0;
}