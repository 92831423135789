
#org-verification_mdl_verifycredential {
     width: 320px;
 }

#org-verification_mdl_verifycredential .modal-header-title {
    flex-grow: 1;
}

#org-verification_mdl_verifycredential .modal-content {
    padding: 16px 16px 32px 16px;
}

#org-verification_mdl_verifycredential .confirmation_status {
    width: 100%;
}
