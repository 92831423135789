
#crd_orgprofile .content-card-header {
    padding: 20px 16px 20px 24px;
}

#crd_orgprofile .crd_details {
    padding: 20px 16px 20px 24px;
}

#crd_orgprofile .td_attachment img {
    width: 18px;
    height: 18px;
    margin: -1px 0 -3px 0;
}