div.cmp_customsecurity-mdl_edit-container .modal-header-title {
    flex-grow: 1;
}

div.cmp_customsecurity-mdl_edit-container .select_field {
    width: 50%;
}

div.cmp_customsecurity-mdl_edit-container div.or_text {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 5px;
    color: #cfd3d5;
}

div.cmp_customsecurity-mdl_edit-container div.access_checkboxes {
    display: flex;
    flex-direction: column;
}

div.cmp_customsecurity-mdl_edit-container div.access_checkboxes .checkbox {
    padding-bottom: 3px;
}

div.cmp_customsecurity-mdl_edit-container div.message.warning {
    margin: 8px 0 0 0;
}