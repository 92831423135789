#crd_org_active_forms .content-card-header {
    padding-right: 8px;
}

#crd_org_active_forms .tbl_credentialicon div {
    display: flex;
    margin-top: 1px;
}

#crd_org_active_forms .td_icon_center {
    display: flex;
    justify-content: center;
    height: 31px;
}

#crd_org_active_forms .td_attachment img {
    width: 18px;
    height: 18px;
    margin: -1px 0 -3px 0;
}

#crd_org_active_forms .td_attachment {
    width: 34px;
}

#crd_org_active_forms .td_attachment img {
    width: 18px;
    height: 18px;
    margin: -1px;
}

#crd_org_active_forms .td_formicon img {
    width: 18px;
    height: 18px;
}

#crd_org_active_forms .td_formstatus {
    display: flex !important;
}

#crd_org_active_forms .td_formicon {
    margin-right: 5px;
}

#crd_org_active_forms .td_assignedto {
    overflow-wrap: break-word;
    max-width: 10rem;
    white-space: normal;
}

/*  media queries --------------------------------------------------------------------------------------------------- */

@media only screen and (max-width: 1300px) {

    #crd_org_active_forms .td_assignedto {
        display: none;
    }
}

@media only screen and (max-width: 1200px) {

    #crd_org_active_forms .td_organization {
        display: none;
    }
}