#org-forms_mdl_updatepackages {
    width: 850px;
}

#org-forms_mdl_updatepackages .modal-header-title {
     flex-grow: 1;
 }
#org-forms_mdl_updatepackages .default{
    margin-top: 6px!important;
    margin-bottom: 10px!important
}
