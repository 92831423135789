
#mdl_org_individuals_indv {
     width: 1100px;
 }

 /*  media queries --------------------------------------------------------------------------------------------------- */

@media only screen and (max-width: 1150px) {

    #mdl_org_individuals_indv {
        width: 900px;
    }
    #mdl_org_individuals_indv .modal-header-tabs-item {
        align-items: center;
    }

    #mdl_org_individuals_indv .modal-header {
        height: 70px;
    }

}

@media only screen and (max-width: 950px) {

    #mdl_org_individuals_indv {
        width: 800px;
    }

    #mdl_org_individuals_indv .modal-header-tabs-item.active {
        height: 50px;
        
    }

}