div#mdl_addrequirement {
    max-width: 500px;
}

div#mdl_addrequirement_container .modal-header-title {
   flex-grow: 1;
}

div#mdl_addrequirement_container .modal-content {
   padding: 16px;
}

div#mdl_addrequirement_container div.field.requirement_ids {
    padding-top: 10px !important;
    flex-grow: 1 !important;
    width: 100%;
    display: flex;
}

div#mdl_addrequirement_container div.field.requirement_ids label{
    width: auto;
    white-space: nowrap;
    padding-top: 0.5rem;
    padding-right: 1rem;
}