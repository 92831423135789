#mdl_indv_requirement {
    width: 600px;
    max-width: 95%;
}

.mdl-indv-requirements-container .modal-header-title {
    flex-grow: 1;
}

.mdl-indv-requirements-container .ui.form .fields > .field.select_field {
    width: 100%;
}

.mdl-indv-requirements-container .credentialoptions {
    padding-top: 16px;
    border-top: 1px dashed #ebebeb;
}

.mdl-indv-requirements-container .credentialoptions_nonpreapproved .credentialoptions_othercreds {
    padding-top: 8px;
}

.mdl-indv-requirements-container .credentialoptions_nonpreapproved {
    padding-top: 16px;
    border-top: 1px dashed #ebebeb;
}

.mdl-indv-requirements-container .credentialoptions_othercreds {
    padding-top: 20px;
}

.mdl-indv-requirements-container .credentialoptions_othercreds button{
    padding-right: 0;
}

.mdl-indv-requirements-container .credentialoptions_instructions {
    padding-bottom: 16px;
}

.mdl-indv-requirements-container .approvedcredential_container {
    padding: 8px 12px;
    display: flex;
    flex-direction: row;
    margin: 8px 4px;
    border: 1px solid #ececec;
    border-radius: 4px;
}

.mdl-indv-requirements-container .approvedcredential_container:hover {
    background-color: #f9f9f9;
}

.mdl-indv-requirements-container .approvedcredential_container.selected {
    border: 1px solid #9fbfd2;
    border-radius: 4px;
    box-shadow: 1px 1px 10px 2px #f4f4f4;
}

.mdl-indv-requirements-container .approvedcredential_container.selected:hover {
    background-color: #ffffff;
}

.mdl-indv-requirements-container .approvedcredential_container.selectable {
    cursor: pointer;
}

.mdl-indv-requirements-container .approvedcredential_icon {
    width: 32px;
    max-width: 32px;
    margin-right: 12px;
}

.mdl-indv-requirements-container .approvedcredential_icon img {
    width: 100%;
    height: 100%;
}

.mdl-indv-requirements-container .approvedcredentialdetail_container {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.mdl-indv-requirements-container .approvedcredential_title {
    font-weight: 600;
    padding-top: 2px;
}

.mdl-indv-requirements-container .approvedcredentialissued_container {
    display: flex;
    flex-direction: row;
}

.mdl-indv-requirements-container .approvedcredentialdetail_name {
    font-size: 11px;
    font-weight: 500;
    color: #cfd3d5;
    line-height: 19px;
    padding-right: 8px;
    white-space: nowrap;
}

.mdl-indv-requirements-container .approvedcredentialissued_container > div.approvedcredentialdetail_name:not(:first-of-type) {
    margin-left: auto;
}

.mdl-indv-requirements-container .approvedcredentialdetail_detail:not(:last-child) {
    padding-right: 5px;
}

.mdl-indv-requirements-container .approvedcredentialdetail_detail:last-child {
    white-space: nowrap;
}

.mdl-indv-requirements-container .detail-item {
    height: fit-content;
}

.mdl-indv-requirements-container .detail-item-text {
    max-width: 425px;
}

.mdl-indv-requirements-container .detail-item-label {
    align-self: flex-start;
}

.mdl-indv-requirements-container .dropdown_option.invalid {
    opacity: 0.5;
}

#selectreq_pckg{
    font-size: 13px;
    line-height: 17px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 0 48px 0;
}

#selectreq_pckg  button {
    width: 400px;
    margin: 8px auto 0 !important;
}

#selectreq_pckg  button:first-of-type {
    margin-top: 16px !important;
}

#selectpackage form {
    margin-top: 16px;
}

#selectpackage .detail-item-label {
    width: 100%;
}

#requirement_warning {
    margin-left: 18px;
    margin-right: 18px;
}