#mdl_addindv {
    width: 550px;
}

#mdl_addindv .modal-header-title {
     flex-grow: 1;
}

#mdl_addindv .modal-content {
    padding: 16px 16px 32px 16px;
}

#mdl_addindv .form.ui {
    padding-top: 10px;
}

#mdl_addindv .individual_ids {
    width: 100%;
    display: flex;
}

#mdl_addindv .individual_ids label {
    width: auto;
    white-space: nowrap;
    padding-top: 0.5rem;
    padding-right: 1rem;
}