#org-dailychecks_mdl_updatequestionniare #tab_questions  {
    width: 100%;
    padding: 5px 16px 16px 16px;
}
#org-dailychecks_mdl_updatequestionniare #tab_questions div.scrolling {
    padding-right: 16px;
    margin-right: -16px;
    margin-bottom: 5px;
    min-height: 50vh;
}

#org-dailychecks_mdl_updatequestionniare .questions-validation-error {
    margin: -8px 16px 8px 16px;
}

#org-dailychecks_mdl_updatequestionniare .modal-footer-buttons > button:first-of-type {
    margin-left: 16px !important;
}

#org-dailychecks_mdl_updatequestionniare .modal-footer-buttons > button.btn_tertiary {
    margin-left: 16px !important;
    margin-left: auto !important;
}

#org-dailychecks_mdl_updatequestionniare #tab_questions .dailycheck-question {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    margin-bottom: 8px;
    background-color: #f6f6f6;
    border-radius: 4px;
    font-size: 13px;
    font-weight: 400;
    color: #4b4f4e;
    border: 1px solid #a1a3a3;
    position: relative;
}

#org-dailychecks_mdl_updatequestionniare #tab_questions .dailycheck-question > div {
    width: 100%;
    padding: 8px 12px 12px 12px;
}

#org-dailychecks_mdl_updatequestionniare #tab_questions .question-toolbar {
    padding: 5px 5px 0 5px !important;
    width: 110%;
    display: flex;
    align-items: center;
}

#org-dailychecks_mdl_updatequestionniare #tab_questions .question-toolbar .question-drag-button {
    cursor: move;
}
#org-dailychecks_mdl_updatequestionniare #tab_questions .question-toolbar .question-drag-button img {
    width: 24px;
    height: 24px;
}

#org-dailychecks_mdl_updatequestionniare #tab_questions .question-toolbar .dailycheck-question-title {
    padding-left: 3px;
    font-weight: bold;
    font-size: 14px;
}

#org-dailychecks_mdl_updatequestionniare #tab_questions .question-toolbar .question-validation {
    margin-left: auto;
    margin-right: 5px;
}

#org-dailychecks_mdl_updatequestionniare #tab_questions .edit_button {
    position: absolute;
    top: 5px;
    right: 5px;
    padding: 9px 13px 7px 12px;
}

#org-dailychecks_mdl_updatequestionniare #tab_questions .edit_button > img {
    width: 24px;
    height: 24px;
}

#org-dailychecks_mdl_updatequestionniare #tab_questions .dailycheck-question-wrapper {
    padding-top: 0 !important;
}

#org-dailychecks_mdl_updatequestionniare #tab_questions .dailycheck-question-text {
    font-weight: bold;
    font-size: 15px;
}

#org-dailychecks_mdl_updatequestionniare #tab_questions .dailycheck-question-wrapper > div:last-of-type {
    border-top: 1px solid #a1a3a3;
    padding-top: 5px;
}

#org-dailychecks_mdl_updatequestionniare #tab_questions .question-option-container {
    display: flex;
    margin-top: 12px;
}

#org-dailychecks_mdl_updatequestionniare #tab_questions .question-option-text {
    width: 150px;
}

#org-dailychecks_mdl_updatequestionniare #tab_questions .question-option-values {
    margin-left: 4px;
    width: 100%;
}

#org-dailychecks_mdl_updatequestionniare #tab_questions .question-add-question-link {
    text-decoration: underline;
    margin-left: 3px;
}

#org-dailychecks_mdl_updatequestionniare #tab_questions .dailycheck-question.dragging {
    opacity: 0.5;
}

#org-dailychecks_mdl_updatequestionniare #tab_questions .question-dropzone {
    width: 100%;
    height: 6px;
    border-radius: 3px;
    margin: -5px 0 3px 0;
}
#org-dailychecks_mdl_updatequestionniare #tab_questions .question-dropzone.is-first {
    margin-top: 5px;
}
#org-dailychecks_mdl_updatequestionniare #tab_questions .question-dropzone.empty {
    width: 100%;
    height: 55px;
    border: 2px dashed #a1a3a3;
    border-radius: 4px;
    background-color: transparent;
    margin: 3px 0 0 0;
    padding: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
#org-dailychecks_mdl_updatequestionniare #tab_questions .question-dropzone.over:not(.empty) {
    background-color: #000000;
}
#org-dailychecks_mdl_updatequestionniare #tab_questions .question-dropzone.over.empty {
    border: 4px dashed #29719a80;
}

.ui.popup.visible > div.content {
    margin-left: 6px;
    margin-right: 6px;
}