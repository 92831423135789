#mdl_assignment_summary {
    margin: 4px 8px;
}

#mdl_assignment_summary .modal-header-title {
    flex-grow: 1;
}

#mdl_assignment_summary .modal-content {
    padding: 0;
}

#mdl_assignment_summary .td_attachment {
    width: 34px;
}

#mdl_assignment_summary .td_attachment img {
    width: 18px;
    height: 18px;
    margin: -1px;
}