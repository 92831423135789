
#indv-forms_mdl_addform {
    width: 500px;
}

#indv-forms_mdl_addform .modal-header-title {
     flex-grow: 1;
 }

#indv-forms_mdl_addform .modal-content {
    padding: 16px 16px 32px 16px;
}

#indv-forms_mdl_addform .individual_id {
    width: 100%;
}

#indv-forms_mdl_addform .individual_id .label {
    width: auto;
}

#indv-forms_mdl_addform  .btn_secondary {
    opacity: 0.5;
    cursor: default;
}

#indv-forms_mdl_addform  .btn_secondary.btn_active {
    opacity: 1;
    cursor: pointer;
}
/*  media queries --------------------------------------------------------------------------------------------------- */

@media only screen and (max-width: 520px) {
    #indv-forms_mdl_addform {
        width: 25rem;
    }
}

@media only screen and (max-width: 380px) {
    #indv-forms_mdl_addform {
        width: 20rem;
    }
}