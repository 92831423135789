#tab_indv_overview_dailycheck .mobile_tab_name {
    display: none;
}

#tab_indv_overview_dailycheck .content-card-content {
    padding: 16px;
}

#tab_indv_overview_dailycheck .subtitle {
    font-size: 11px;
    font-weight: 500;
    color: #c6cecc;
    padding-bottom: 8px;
}

#tab_indv_overview_dailycheck .cmp14days {
    padding: 0 0 24px 0;
}


/*  media queries --------------------------------------------------------------------------------------------------- */

@media only screen and (max-width: 1000px) {

    #tab_indv_overview_dailycheck .mobile_tab_name {
        padding-top: 5%;
        padding-bottom: 5%;
        display: block;
        text-align: center;
    }
}

@media only screen and (max-width: 880px) {

    #tab_indv_overview_dailycheck .td_history {
        display: none;
    }
}

@media only screen and (max-width: 880px) {

    #tab_indv_overview_dailycheck .td_manageroverride {
        display: none;
    }
}

@media only screen and (max-width: 750px) {

    #tab_indv_overview_dailycheck .td_location {
        display: none;
    }

}

@media only screen and (max-width: 500px) {

    #tab_indv_overview_dailycheck .td_template {
        display: none;
    }


}