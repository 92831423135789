
.selectfile {
    width: 100%;
    color: #b3b6b8 !important;
}

.cmp_fileupload .message {
    margin-top: 20px;
    height: 38px;
}

.cmp_fileupload .ui.progress {
    margin-top: 20px !important;
    height: 38px;
}

.fileupload {
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.cmp_fileupload .file-dropzone {
    display: flex;
    flex-direction: column;
    padding: 20px;
    align-items: center;
    border-width: 2px;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    margin-bottom: 20px;
}

.cmp_fileupload .dropzone-user-instruction {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
}

.cmp_fileupload .dropzone-user-instruction > * {
    margin: 8px 0;
}

.cmp_fileupload .dropzone-user-instruction img {
    width: 30px;
    height: 30px;
}

/*  media queries --------------------------------------------------------------------------------------------------- */

@media only screen and (max-width: 450px) {

    .cmp_fileupload {
        max-width: 18rem;
    }

    .cmp_fileupload .message {
        height: 55px !important;
    }

    .cmp_fileupload .file-dropzone {
        width: 280px;
    }
}