#org-dailycheck_mdl_question {
    width: 700px;
}

#org-dailycheck_mdl_question .category {
    width: 200px;
    margin-right: 4px !important;
}
#org-dailycheck_mdl_question .visible_to_individual_type {
    margin-left: 5px;
}
#org-dailycheck_mdl_question .ui.buttons > button.ui.button {
    height: 38px;
    font-size: 11px;
}
#org-dailycheck_mdl_question .ui.buttons .or {
    width: 0;
}
#org-dailycheck_mdl_question .ui.buttons .or:before {
    border: 1px solid #e5e7e8;
    margin-top: -0.75em;
    line-height: 1.61em;
    font-size: 10px;
}
#org-dailycheck_mdl_question .ui.buttons .button.btn_secondary:first-child {
    border-left: 1px solid #e5e7e8;
}
#org-dailycheck_mdl_question .ui.buttons .button.btn_primary.btn_active:first-child {
    border-left: 1px solid #29719a;
}

#org-dailycheck_mdl_question #question {
    height: 96px;
    line-height: 14px;
    margin-bottom: 16px;
}

#org-dailycheck_mdl_question .yes_no_group {
    height: 45px;
}
#org-dailycheck_mdl_question div.yes_no_label {
    width: 50px;
}
#org-dailycheck_mdl_question .quarantinedays {
    margin-top: -8px;
    margin-left: 25px;
}
#org-dailycheck_mdl_question .quarantinedays input {
    width: 100px;
    margin-left: 10px;
}
#org-dailycheck_mdl_question .sub_question_message {
    font-size: 12px;
    color: #76888e;
}

#reqfielderror {
    display: flex;
    flex-direction: row;
}