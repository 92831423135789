#mdl_add_credential {
    width: 600px;
}    
    
#mdl_add_credential .ui.form .fields > .field.select_field {
    width: 100%;
}

#mdl_add_credential .detail-spacer {
    height: 10px;
}