#crd_orgdetails {
    margin-right: 8px;
    margin-bottom: 16px;
}

#crd_orgdetails .content-card-content {
    padding: 24px 24px 32px 24px;
}

#crd_orgdetails .detail-spacer {
    height: 4px;
}

#crd_orgdetails .detail-item {
    display: grid;
    grid-template-columns: 110px auto;
}

#crd_orgdetails .detail-item-label {
    font-size: 11px;
    font-weight: 500;
    color: #cfd3d5;
}

@media only screen and (max-width: 700px) {

    #crd_orgdetails {
        width: 100%;
        margin-right: 0;
    }
    
}