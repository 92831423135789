
#crd_org_individuals_individuals .content-card-header {
    padding-right: 8px;
}

#crd_org_individuals_individuals .td_overflow {
    max-width: 12rem;
}

/*  media queries --------------------------------------------------------------------------------------------------- */

@media only screen and (max-width: 1570px) {

    #crd_org_individuals_individuals .td_businessunit {
        display: none;
    }
}

@media only screen and (max-width: 1430px) {

    #crd_org_individuals_individuals .td_city {
        display: none;
    }

}

@media only screen and (max-width: 1310px) {

    #crd_org_individuals_individuals .td_questionnaire {
        display: none;
    }

}