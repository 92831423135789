/*  media queries --------------------------------------------------------------------------------------------------- */

@media only screen and (max-width: 1150px) {


}

@media only screen and (max-width: 1060px) {

    .public_location_section_body .td_invite_status {
        display: none;
    }
}

@media only screen and (max-width: 700px) {

    .public_location_section_body .td_permissions {
        display: none;
    }
}

@media only screen and (max-width: 530px) {

    .public_location_section_body .td_email {
        display: none;
    }

}