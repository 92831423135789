
#org-locations-form_mdl_assign {
    width: 500px;
}

#org-locations-form_mdl_assign .modal-header-title {
     flex-grow: 1;
 }

 #org-locations-form_mdl_assign .modal-content {
    padding: 16px 16px 32px 16px;
}

#org-locations-form_mdl_assign .individual_id {
    width: 100%;
    display: flex;
}

#org-locations-form_mdl_assign .individual_id label{
    width: auto;
    white-space: nowrap;
    padding-top: 0.5rem;
    padding-right: 2.5rem;
}