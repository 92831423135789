
#mdl_manageroverride {
     width: 420px;
 }

#mdl_manageroverride .name {
    width: 100%;
}

#mdl_manageroverride .url {
    width: 100%;
}