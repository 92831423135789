#org_location_doc_tab .content-filter-archive {
  align-self: flex-start;
  flex-grow: 1;
}

#org_location_doc_tab .pagination {
  align-self: flex-end;
}

#org_location_doc_tab .content-filter {
  justify-content: inherit;
}

#org_location_doc_tab .td_attachment {
  width: 34px;
}

#org_location_doc_tab .td_attachment img {
  width: 18px;
  height: 18px;
  margin: -1px 0 -3px 0;
}
