#org-locations_mdl_dailycheckreport {
    width: 400px;
}

#org-locations_mdl_dailycheckreport .modal-header-title {
   flex-grow: 1;
}

#org-locations_mdl_dailycheckreport .modal-content {
   padding: 16px 16px 32px 16px;
}

#org-locations_mdl_dailycheckreport #datefrom, #org-locations_mdl_dailycheckreport #dateto {
    width: 100%;
}

#org-locations_mdl_dailycheckreport .modal-content .column > .field, #org-locations_mdl_dailycheckreport .modal-content .ui.input {
    display: block !important
}