
#mdl_org_individuals_indv #tab_locations .td_attachment img {
    width: 18px;
    height: 18px;
    margin: -1px;
}

#mdl_org_individuals_indv #tab_locations {
    padding: 0 8px 16px 8px;
}
