#mdl_cmp_verifiablepresentationviewer {
    max-height: 90vh;
}

.credentialdetails {
    margin-top: 20px;
    margin-left: 20px;
    overflow: auto;
}

#mdl_cmp_verifiablepresentationviewer .issued-expired-form {
    margin-top: 20px;
}

#mdl_cmp_verifiablepresentationviewer .message.warning {
    margin-top: 25px;
}

#mdl_cmp_verifiablepresentationviewer .student-id-field {
    max-width: 260px;
}

#mdl_cmp_verifiablepresentationviewer .modal-content {
    display: flex;
    min-height: 30rem;
    padding-top: 0;
    padding-bottom: 0;
}

#mdl_cmp_verifiablepresentationviewer .page {
    display: flex;
    flex-direction: column;
    margin-left: 2rem;
    margin-right: 2rem;
    min-width: 36.5rem;
}

#mdl_cmp_verifiablepresentationviewer .next_btn {
    width: 10rem;
    align-self: flex-end;
}

#mdl_cmp_verifiablepresentationviewer .modal-header-title {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
}

#mdl_cmp_verifiablepresentationviewer .modal-header-img {
    height: 2rem;
    width: 2rem;
}

#mdl_cmp_verifiablepresentationviewer .modal-header-text {
    margin-top: 5px;
    margin-left: 5px;
}

#mdl_cmp_verifiablepresentationviewer .page-title {
    font-size: 16px;
    font-weight: 700;
    color: #36484e;
    padding-bottom: 16px;
    display: flex;
    margin-top: 15px;
}

#mdl_cmp_verifiablepresentationviewer .description-text {
    font-size: 13px;
    font-weight: 400;
    color: #717473;
    padding-bottom: 16px;
    display: flex;
    margin-bottom: 30px;
    flex-direction: column;
}

#mdl_cmp_verifiablepresentationviewer .steps_panel {
    border-right: 2px solid #878e96;
    width: 20rem;
}

#mdl_cmp_verifiablepresentationviewer .app-sidenav-item {
    margin: 15px 20px 0 -16px;
    display: flex;
    flex-direction: column;
    padding-left: 2px;
    justify-content: center;
    height: 5rem;
    margin-left: -16px;
}

#mdl_cmp_verifiablepresentationviewer .section-title {
    font-size: 15px;
    font-weight: 600;
    margin-left: 15px;
}

#mdl_cmp_verifiablepresentationviewer .section-description {
    margin-left: 15px;
}

#mdl_cmp_verifiablepresentationviewer .app-sidenav-item.active {
    border-left: 4px solid #29719a;
    margin-right: 0px;
    border-right: 0px;
}

#mdl_cmp_verifiablepresentationviewer .skip_btn {
    border: 0px;
    align-self: flex-end;
    padding-right: 0px;
    margin-right: 10px !important;
}

#mdl_cmp_verifiablepresentationviewer .previous_btn {
    border: 0px;
    align-self: flex-start;
    padding-left: 8px;
}

#mdl_cmp_verifiablepresentationviewer .skip-icon {
    margin-left: 2px;
    margin-bottom: 3px;
}

#mdl_cmp_verifiablepresentationviewer .previous-icon {
    margin-right: 2px;
    margin-bottom: 3px;
}

/*#mdl_cmp_verifiablepresentationviewer .modal-footer {*/
/*    align-items: flex-end;*/
/*    flex-grow: 1;*/
/*    background-image: none;*/
/*    background-color: #ffffff;*/
/*}*/

/*#mdl_cmp_verifiablepresentationviewer .modal-footer-buttons {*/
/*    margin-bottom: 20px;*/
/*    padding-right: 0px;*/
/*    justify-content: space-between;*/

/*}*/

#mdl_cmp_verifiablepresentationviewer .issuedby {
    width: 100%;
}

#mdl_cmp_verifiablepresentationviewer .field {
    margin-right: 8px;
}

#mdl_cmp_verifiablepresentationviewer .credential {
    width: 100%;
}

#mdl_cmp_verifiablepresentationviewer .mdl_credential_dates {
    width: 100%;
    display: grid !important;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 8px;
}

#mdl_cmp_verifiablepresentationviewer #issued {
    height: 38px;
    width: 252px !important;
}

#mdl_cmp_verifiablepresentationviewer #expires {
    height: 38px;
    width: 252px !important;
}

#mdl_cmp_verifiablepresentationviewer .credential {
    margin: 0px;
}

#mdl_cmp_verifiablepresentationviewer .multi_upload {
    margin-bottom: 6px !important;
}

.detail-item-header {
    margin-bottom: 20px;
    height: 3rem;
    max-width: 430px;
}

.digitalcertificate_detail_item {
    height: 16px;
    float: left;
}

.digitalcertificate_detail_item .verified_icon {
    height: 12px;
    width: 12px;
    margin-right: 5px;
}

.vc_data_label {
    width: 70px;
    float: left;
}

.vc_data {
    padding-left: 10px;
    width: 290px !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}

.json-item {
    margin-top: 5rem;
}

.modal-footer-action {
    margin-left: 20px;
}

.modal-footer-action-label {
    font-weight: bolder;
    position: relative;
    top: -8px;
    padding-right: 15px;
}

.modal-footer-action-detail {
    position: relative;
    top: -8px;
    padding: 5px;
}

/*  media queries --------------------------------------------------------------------------------------------------- */

@media only screen and (max-width: 800px) {

    #mdl_cmp_verifiablepresentationviewer {
        max-width: 50rem;
    }
}

@media only screen and (max-width: 710px) {

    #mdl_cmp_verifiablepresentationviewer .steps_panel {
        display: none;
    }

    #mdl_cmp_verifiablepresentationviewer {
        max-width: 40rem;
    }

    #mdl_cmp_verifiablepresentationviewer .page {
        margin: 0%;
    }
}

@media only screen and (max-width: 600px) {

    #mdl_cmp_verifiablepresentationviewer {
        max-width: 30rem;
    }

    #mdl_cmp_verifiablepresentationviewer .page {
        min-width: 20rem;
    }

    #mdl_cmp_verifiablepresentationviewer #expires {
        margin-left: 0px !important;
    }
}

@media only screen and (max-width: 450px) {

    #mdl_cmp_verifiablepresentationviewer {
        max-width: 23rem;
    }

    #mdl_cmp_verifiablepresentationviewer .page {
        min-width: 15rem;
    }

    #mdl_cmp_verifiablepresentationviewer .modal-header-text {
        font-size: 18px;
    }

    #mdl_cmp_verifiablepresentationviewer .description-text {
        padding-bottom: 0px;
    }

}
