#credentials_mdl_credential_details .credential-status {
    display: flex;
    align-items: center;
    padding-bottom: 8px;
}

#credentials_mdl_credential_details .credential-status img {
    width: 28px;
    height: 28px;
}

#credentials_mdl_credential_details div.credential-status div {
    padding-left: 8px;
    font-size: 24px;
    font-weight: 500;
}

#credentials_mdl_credential_details div.credential-status div.credential-status-invalid {
    color: #e0454f;
}

#credentials_mdl_credential_details div.credential-status div.credential-status-selfverified {
    color: #29719a;
}

#credentials_mdl_credential_details div.credential-status div.credential-status-active {
    color: #69b536;
}

#credentials_mdl_credential_details div.credential-status div.credential-status-pending {
    color: #f2ae02;
}

#credentials_mdl_credential_details .credential-holder {
    font-weight: 500;
    font-size: 20px;
    padding: 4px 0;
}

#credentials_mdl_credential_details .credential-name {
    font-weight: 500;
    font-size: 18px;
    padding: 4px 0;
}

#credentials_mdl_credential_details .credential-issuedby {
    font-weight: 400;
    font-size: 16px;
    color: #36484e;
    padding: 4px 0;
}

#credentials_mdl_credential_details .credential-row {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 2px 0;
}

#credentials_mdl_credential_details .credential-label {
    font-weight: 500;
    font-size: 14px;
    margin-right: 4px;
}

#credentials_mdl_credential_details .credential-label:not(:first-child) {
    margin-left: 8px;
}

#credentials_mdl_credential_details .credential-value {
    font-weight: 400;
    font-size: 14px;
    color: #36484e;
}

#credentials_mdl_credential_details .credential-transaction-row {
    align-items: center;
}

#credentials_mdl_credential_details .credential-transaction-row img {
    width: 16px;
    height: 16px;
    margin-right: 4px;
}

#credentials_mdl_credential_details .credential-actionbar {
    display: flex;
}

#mdl_confirmation {
    width: 550px !important   
}

#mdl_confirmation .modal-content {
    text-align: left;
}
