#crd_questionnaire {
    max-width: 1000px;
}

#crd_questionnaire .questionnaire-question{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
    margin-bottom: 8px;
    background-color: #f8f8f9;
    border-radius: 4px;
}

#crd_questionnaire .questionnaire-question-wrapper{
    padding-right: 24px;
    width: calc(100% - 100px);
}

#crd_questionnaire .questionnaire-question-category{
    font-size: 11px;
    font-weight: 500;
    color: #a1a3a3;
}

#crd_questionnaire .questionnaire-question-answer {
    display: flex;
    flex-direction: row;
}

#crd_questionnaire .questionnaire-question-answer div{
    width: 40px;
    height: 40px;
    border-radius: 20px;
    border: 1px solid #d5d5d5;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    line-height: 16px;
    cursor: pointer;
    margin-left: 8px;
}

#crd_questionnaire .answer_active {
    background-color: #29719a;
    color: #fff;
    border-color: #29719a !important;
}
