#mdl_updatestatus {
    width: 350px;
}

#mdl_updatestatus .modal-header-title {
    flex-grow: 1;
}

#mdl_updatestatus .modal-content {
    padding: 16px 16px 32px 16px;
}

#mdl_updatestatus .confirmation_status {
    width: 100%;
}
