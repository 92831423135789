#org-locations_mdl_downloadform {
    width: 500px;
}

#org-locations_mdl_downloadform .modal-header .modal-header-close {
    margin-left: auto;
}

div.loc-forms-bulkdownload div.instructions {
    margin-bottom: 20px;
}

div.loc-forms-bulkdownload .filter_dates {
    width: 275px !important;
}