div.cmp_customsecurity-container .modal-header-title {
    flex-grow: 1;
}

div.cmp_customsecurity-container div.has_customsecurity_checkbox {
    padding: 8px 0 0 8px;
}

div.cmp_customsecurity-container div.content-filter {
    padding-top: 0;
}

div.cmp_customsecurity-container div.message.warning {
    margin: 8px 0 0 0;
}