#credentials_mdl_credential_details .new-version {
    display: flex;
    flex-direction: column;
}

#credentials_mdl_credential_details .new-version .title {
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 8px;
}

#credentials_mdl_credential_details .new-version .title-description {
    font-size: 14px;
    font-weight: 400;
    color: #36484e;
    padding-bottom: 12px;
}

#credentials_mdl_credential_details .new-version .detail-row {
    display: flex;
    align-items: flex-start;
    padding: 3px 0;
}

#credentials_mdl_credential_details .new-version .input-row {
    display: flex;
    align-items: center;
    padding: 3px 0;
}

#credentials_mdl_credential_details .new-version .detail-row .detail-label,
#credentials_mdl_credential_details .new-version .input-row .detail-label {
    font-weight: 500;
    font-size: 14px;
    width: 150px;
}

#credentials_mdl_credential_details .new-version .detail-row .detail-value {
    font-size: 14px;
    color: #36484e;
}

#credentials_mdl_credential_details .new-version .input-row div.detail-input div.field {
    padding-bottom: 0 !important;
    width: 210px;
}

#credentials_mdl_credential_details .new-version .document-title-row {
    display: flex;
    flex-direction: row;
    margin-top: 8px;
    padding: 3px 0;
}

#credentials_mdl_credential_details .new-version .document-title-row .document-title {
    font-weight: 500;
    font-size: 14px;
}

#credentials_mdl_credential_details .new-version .document-title-row .document-title-description {
    font-size: 14px;
    color: #36484e;
    margin-left: 5px;
}

#credentials_mdl_credential_details .new-version .document-footer {
    font-size: 12px;
    color: #36484e;
}

#credentials_mdl_credential_details .new-version .document-component .file-dropzone {
    margin-bottom: 8px;
}