
#crd_org_credentials_verifications .td_attachment {
    width: 34px;
}

#crd_org_credentials_verifications .td_attachment img {
    width: 18px;
    height: 18px;
    margin: -1px 0 -3px 0;
}

