#credentials_mdl_credential_details .credential-historical {
    display: flex;
    flex-direction: column;
}

#credentials_mdl_credential_details .credential-historical .credential-revision-container {
    padding: 4px 0;
    border-bottom: 1px solid #e5e7eb;
}

#credentials_mdl_credential_details .credential-historical .revision-no-results,
#credentials_mdl_credential_details .credential-historical .revision-load-more {
    display: flex;
    justify-content: center;
}

#credentials_mdl_credential_details .credential-historical .revision-no-results,
#credentials_mdl_credential_details .credential-historical .loader {
    margin-top: 12px;
}

#credentials_mdl_credential_details .credential-historical .revision-load-more .btn_tertiary {
    margin-left: unset !important;
}

#credentials_mdl_credential_details .credential-historical .credential-revision-container .revision-header {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 8px;
}

#credentials_mdl_credential_details .credential-historical .credential-revision-container .revision-header img:first-of-type {
    padding-right: 8px;
}

#credentials_mdl_credential_details .credential-historical .credential-revision-container .revision-header img:last-of-type {
    margin-left: auto;
}

#credentials_mdl_credential_details .credential-historical .credential-revision-container.revision-collapsed .revision-body {
    display: none;
}

#credentials_mdl_credential_details .credential-historical .credential-revision-container .revision-body-row {
    display: flex;
    padding: 4px 0;
    align-items: flex-start;
}

#credentials_mdl_credential_details .credential-historical .credential-revision-container .revision-label {
    font-size: 14px;
    font-weight: 500;
    width: 125px;
}

#credentials_mdl_credential_details .credential-historical .credential-revision-container .revision-value {
    font-size: 14px;
    color: #36484e;
}

#credentials_mdl_credential_details .credential-historical .credential-revision-container .revision-document-value .btn_tertiary_link {
    padding-top: 0;
    padding-bottom: 0;
}

#credentials_mdl_credential_details .credential-historical .credential-revision-container .revision-icon-value {
    display: flex;
    align-items: center;
}

#credentials_mdl_credential_details .credential-historical .credential-revision-container .revision-icon-value img {
    width: 16px;
    height: 16px;
    margin-right: 4px;
}

/*
#credentials_mdl_credential_details .credential-historical
#credentials_mdl_credential_details .credential-historical
#credentials_mdl_credential_details .credential-historical
#credentials_mdl_credential_details .credential-historical
#credentials_mdl_credential_details .credential-historical
#credentials_mdl_credential_details .credential-historical
#credentials_mdl_credential_details .credential-historical
#credentials_mdl_credential_details .credential-historical
#credentials_mdl_credential_details .credential-historical
#credentials_mdl_credential_details .credential-historical
#credentials_mdl_credential_details .credential-historical
#credentials_mdl_credential_details .credential-historical
#credentials_mdl_credential_details .credential-historical
*/