
#mdl_indv_wallet_details_sharing_updatelink {
    width: 300px;
}

#mdl_indv_wallet_details_sharing_updatelink .modal-header-title {
    flex-grow: 1;
}

.label {
    display: flex;
    width: 100%;
}