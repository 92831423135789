
#mdl_org_individuals_inviteindividual {
     width: 420px;
 }

#mdl_org_individuals_inviteindividual #firstname {
    margin-right: 8px;
}

#mdl_org_individuals_inviteindividual .individual_type {
    width: 100%;
}