
.login_wrapper {
    display: grid;
    justify-items: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background-color: #F3F4F6;
}

.logowrapper {
    display: flex;
    flex-direction: column;
}

#CrediveraLogo {
    width: 200px;
    padding-bottom: 16px;
    margin: 0 auto;
}


.TOC {
    color: #363c5b;
    font-weight: 300;
    font-size: 13px;
    line-height: 14px;
    padding-bottom: 24px;
}

.login {
    width: 300px;
    border: 1px solid #eaeaea;
    padding: 28px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    box-shadow: 0 0.0625rem 0.1875rem rgb(0 0 0/10%), 0 0.0625rem 0.125rem rgb(0 0 0/6%)
}

.login_logo {
    text-align: center;
}

.login_logo img{
    width: 90%;
}

.email {
    width: 100%;
}

.password {
    width: 100%;
}

#btn_login {
    margin-left: 0 !important;
    width: 100% !important;
}

#btn_signup {
    width: 100%;
    margin-left: 0 !important;
    margin-top: 8px !important;
    background-color: #ffffff;
    border: 1px solid #29719a;
}


.or_sso {
    text-align: center;
    margin: 15px;
}

#btn_sso {
    margin-left: 0 !important;
    width: 100% !important;
}

@media only screen and (max-width: 615px) {

    .login_wrapper .ui.input>input,
    .login_wrapper .ui.form .field.field input {
        font-size: 16px !important;
    }

}
