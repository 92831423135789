#org-locations_mdl_dailycheckotherloc .td_override_spacer {
    padding-top: 13px;
}

#org-locations_mdl_dailycheckotherloc {
    width: 650px !important;
}

#org-locations_mdl_dailycheckotherloc .modal-content {
    padding: 0;
}

#org-locations_mdl_dailycheckotherloc .content-filter {
    padding-top: 16px;
}

/*  media queries --------------------------------------------------------------------------------------------------- */

@media only screen and (max-width: 1160px) {

    #org-locations_mdl_dailycheckotherloc {
        width: 850px !important;
    }
}

@media only screen and (max-width: 880px) {

    #org-locations_mdl_dailycheckotherloc {
        width: 700px !important;
    }
}
