
#org-networks_mdl_response {
     width: 550px;
 }

#org-networks_mdl_response .modal-header-title {
    flex-grow: 1;
}

#org-networks_mdl_response .modal-content {
    padding: 16px 16px 32px 16px;
}

