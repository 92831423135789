
.login_wrapper {
    display: grid;
    justify-items: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background-color: #F3F4F6;
}

.TOC {
    color: #363c5b;
    font-weight: 300;
    font-size: 13px;
    line-height: 14px;
    padding-bottom: 24px;
}

#btn_backtologin {
    margin-top: 16px !important;
}

.countdown {
    color: #363c5b;
    font-weight: 300;
    font-size: 13px;
    line-height: 14px;
    padding-top: 24px;
}

.request,
.confirmation {
    width: 300px;
    border: 1px solid #eaeaea;
    padding: 28px;
    border-radius: 5px;
    background-color: #ffffff;
}

.request Form {
    display: flex;
    flex-direction: column;
    padding-bottom: 16px;
}

#btn_submitrequest {
    margin-left: 0 !important;
}

.login_logo {
    text-align: center;
}

.login_logo img{
    width: 90%;
}


.passwordvalidation_note {
    color: #363c5b;
    font-weight: 300;
    font-size: 13px;
    line-height: 14px;
    padding-bottom: 24px;
}

.passwordvalidation {
    color: #363c5b;
    font-weight: 300;
    font-size: 13px;
    line-height: 16px;
    padding-bottom: 16px;
}

.passwordvalidation_item {
    display: flex;
    padding-bottom: 2px;
}

.passwordvalidation_item_icon {
    width: 16px;
    margin-right: 6px;
    margin-top: -3px;
}

.passwordvalidation_item_icon img {
    width: 100%;
    height: 100%;
}

#btn_confirmation {
    margin-top: 16px !important;
}

.btn_backtologin {
    color: #29719a;
    font-weight: 500;
    font-style: italic;
    cursor: pointer;
    padding: 16px 0px 0px 0px;
}

