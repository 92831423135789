#mdl_sharing {
    width: 1100px;
}

#mdl_sharing .modal-header-title {
    flex-grow: 1;
}

.td_share_icon img {
    max-width: 18px;
}

.td_sharing_hstr_icon img {
    max-width: 18px;
}

#mdl_sharing .td_link_icon {
    display: table-cell;
}

#mdl_sharing .td_text_wrap {
    white-space: normal;
}

@media only screen and (max-width: 1180px) {

    #mdl_sharing {
        width: 800px;
    }
}

@media only screen and (max-width: 950px) {

    #mdl_sharing {
        width: 700px;
    }
}