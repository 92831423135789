#tab_indv_wallet_history .mobile_tab_name {
    display: none;
}

#tab_indv_wallet_dailycheck .content-card-content {
    padding: 16px;
}

#tab_indv_wallet_dailycheck .subtitle {
    font-size: 11px;
    font-weight: 500;
    color: #c6cecc;
    padding-bottom: 8px;
}

#tab_indv_wallet_dailycheck .checkresultswrapper {
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
}

#tab_indv_wallet_dailycheck .checkresult {
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    padding: 8px;
    margin-right: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 64px;
    margin-bottom: 4px;
}

#tab_indv_wallet_dailycheck .checkresult_text {
    padding-bottom: 8px;
    font-weight: 500;
    text-align: center;
}

#tab_indv_wallet_dailycheck .checkresult_icon {
    height: 32px;
    width: 32px;
}

#tab_indv_wallet_dailycheck .checkresult_icon img {
    height: 100%;
    width: 100%;
}

#tab_indv_wallet_dailycheck .tbl_icon {
    margin-right: 8px;
    margin-top: -1px;
}

#tab_indv_wallet_dailycheck .tbl_dailycheck {
    padding-left: 16px !important;
}


/*  media queries --------------------------------------------------------------------------------------------------- */

@media only screen and (max-width: 1200px) {

    #tab_indv_wallet_history .td_transactionid {
        display: none;
    }
}

@media only screen and (max-width: 700px) {

    #tab_indv_wallet_history .td_transactionby {
        display: none;
    }

}

@media only screen and (max-width: 600px) {

    #tab_indv_wallet_history .td_transactionorg {
        display: none;
    }
}

@media only screen and (max-width: 500px) {

    #tab_indv_wallet_history .td_description {
        overflow-wrap: break-word;
        max-width: 10rem;
        white-space: normal;
      }
    
    #tab_indv_wallet_history .mobile_tab_name {
        padding-top: 5%;
        padding-bottom: 5%;
        display: block;
        text-align: center;
    }

}
