
#sta_org_overview_onsite {
    width: 280px;
    margin-right: 8px;
}

#sta_org_overview_onsite .chartwrapper {
    display: flex;
    flex-direction: row;
}

#sta_org_overview_onsite .chartlegend {
    display: flex;
    flex-direction: column;
    padding: 0 0 0 16px;
    justify-content: center;
}

#sta_org_overview_onsite .chartlegend-item {
    display: flex;
    flex-direction: row;
    padding: 2px 0px 2px 8px;
}

#sta_org_overview_onsite .chartlegend-item.spacer {
    padding-top: 12px;
}

#sta_org_overview_onsite .chartlegend-item_icon {
    height: 19px;
    width: 19px;
}

#sta_org_overview_onsite .chartlegend-item_icon img {
    width: 100%;
    height: 100%;
}

#sta_org_overview_onsite .chartlegend-item_text {
    padding: 0 0 0 8px;
    font-size: 13px;
    font-weight: 400;
    color: #676C6B;
}
