
.digitalcertificate_wrapper {
    height: 100vh;
    width: 100vw;
}

#digitalcertificate {
    display: flex;
    flex-direction: column;
    padding: 0;
    background-image: url(/icons/verified-seal-grayscale.png);
    background-repeat: no-repeat;
    background-size: 100%;
    max-width: 800px;
    margin: 48px auto;
    position: relative;
    box-shadow: 0 0 16px 0 rgb(25 40 44 / 35%);
}

#digitalcertificate .digitalcertificate_icon_small {
    width: 15px;
    margin-right: 5px;
}

#digitalcertificate .digitalcertificate_header {
    padding: 64px 32px 32px 32px;
    display: flex;
    flex-direction: row;
}

#digitalcertificate  .digitalcertificate_icon {
    height: 52px;
    width: 52px;
    margin-right: 16px;
}

#digitalcertificate .digitalcertificate_title {
    font-size: 48px;
    font-weight: 700;
    line-height: 50px;
    color: #69b536;
}

#digitalcertificate .self_verified {
    font-size: 48px;
    font-weight: 700;
    line-height: 50px;
    color: #29719a;
}

#digitalcertificate .digitalcertificate_content_1 {
    padding: 56px 32px;
}

#digitalcertificate .digitalcertificate_content_1_organization {
    font-size: 40px;
    font-weight: 700;
    line-height: 36px;
}

#digitalcertificate .digitalcertificate_content_1_credential {
    font-size: 28px;
    line-height: 26px;
    font-weight: 500;
    letter-spacing: -1px;
    padding: 16px 0 24px;
}

#digitalcertificate  .digitalcertificate_content_2 {
    padding: 0 32px 32px 32px;
}

#digitalcertificate .digitalcertificate_content_2_issuedby {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -1px;
}

#digitalcertificate .digitalcertificate_content_2_dependant {
    font-size: 14px;
    padding-top: 4px;
}

#digitalcertificate .digitalcertificate_content_2_issued_date {
    font-size: 14px;
    padding-top: 2px;
}

#digitalcertificate .digitalcertificate_content_3 {
    padding: 16px 32px 32px;
}

#digitalcertificate .digitalcertificate_content_3_view_vc,
#digitalcertificate .digitalcertificate_content_3_view_document {
    color: #2a7dad;
    text-decoration: underline;
    cursor: pointer;
}

#digitalcertificate .digitalcertificate_detail {
    border-top: 1px dashed #e5e7e8;
    border-bottom: 1px dashed #e5e7e8;
    padding: 16px 32px;
    display: flex;
    align-items: center;
}

#digitalcertificate .digitalcertificate_detail_logo {
    display: flex;
    margin-right: 32px;
}

#digitalcertificate .digitalcertificate_detail_logo img {
    max-height: 90px;
    max-width: 176px;
}

#digitalcertificate .digitalcertificate_detail_list {
    flex: 1;
}

#digitalcertificate .signature-data {
    width: 400px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

#digitalcertificate .digitalcertificate_detail_list .detail_item_label {
    font-weight: 500;
    font-size: 11px;
    color: #cfd3d5;
    min-width: 160px;
}

#digitalcertificate .digitalcertificate_description {
    border-top: 1px dashed #e5e7e8;
    padding: 16px 32px;
    display: flex;
    align-items: center;
    padding: 16px 32px;
}

#digitalcertificate .digitalcertificate_description_logo {
    margin-right: 32px;
    display: flex;
}

#digitalcertificate .digitalcertificate_description_logo img {
    max-width: 190px;
    max-height: 104px;
}

#digitalcertificate .digitalcertificate_description_detail_item + .digitalcertificate_description_detail_item {
    margin-top: 20px;
}

#digitalcertificate .description_detail_title {
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
}

#digitalcertificate .digitalcertificate_footer {
    text-align: center;
    color: #cfd3d5;
    padding: 16px 16px;
}

#digitalcertificate .invalid {
    color: #e0454f;
}

#digitalcertificate .pending {
    color: #f2ae02;
}


@media only screen and (max-width: 700px) {

    #digitalcertificate .digitalcertificate_detail {
        flex-direction: column;
    }

    #digitalcertificate .digitalcertificate_detail_logo {
        margin-bottom: 24px;
    }

    #digitalcertificate .digitalcertificate_description {
        flex-direction: column;
    }

    #digitalcertificate .digitalcertificate_description_logo {
        margin-top: 24px;
        margin-bottom: 24px;
    }
}
