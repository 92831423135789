#indv-forms_mdl_uploadform {
    width: 545px;
    margin-right: 8px;
    margin-bottom: 4px;
}

#indv-forms_mdl_uploadform .modal-header-title {
    flex-grow: 1;
}

#indv-forms_mdl_uploadform .modal-content {
    padding: 16px 16px 32px 16px;
}

#indv-forms_mdl_uploadform .formdetails {
    border-bottom: 1px dashed #e5e7e8;
    padding-bottom: 16px;
    margin-bottom: 16px;
}

#indv-forms_mdl_uploadform .content-card-content {
    padding: 0 24px 32px 24px;
}

#indv-forms_mdl_uploadform .detail-spacer {
    height: 4px;
}

#indv-forms_mdl_uploadform .detail-item {
    display: flex;
    flex-direction: row;
    height: unset;
    padding-bottom: 4px;
}

#indv-forms_mdl_uploadform .detail-item-label {
    display: flex;
    min-width: 110px;
    font-size: 11px;
    font-weight: 500;
    color: #cfd3d5;
}

#indv-forms_mdl_uploadform .detail-item-text {
    display: flex;
    line-height: 14px;
}