
.public_content {
    background-color: #eef1f0;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background-image: url("data:image/svg+xml, %3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'56'%20height%3D'100'%3E%0A%3Crect%20width%3D'56'%20height%3D'100'%20fill%3D'%23eef0f0'%2F%3E%0A%3Cpath%20d%3D'M28%2066L0%2050L0%2016L28%200L56%2016L56%2050L28%2066L28%20100'%20fill%3D'none'%20stroke%3D'%23f3f4f4'%20stroke-width%3D'2'%2F%3E%0A%3Cpath%20d%3D'M28%200L28%2034L0%2050L0%2084L28%20100L56%2084L56%2050L28%2034'%20fill%3D'none'%20stroke%3D'%23f4f4f4'%20stroke-width%3D'2'%2F%3E%0A%3C%2Fsvg%3E");
}

#pg_publicprofile .public_wrapper {
    width: 100%;
    max-width: 1200px;
    margin: 30px auto;
}

.profile_report_card {
    background-color: #ffffff;
    border: 1px solid #e5e7e8;
    border-radius: 4px 4px 0 0;
}

.profile_report_header {
    margin-bottom: 20px;
}
.profile_report_header_top {
    display: flex;
    align-items: center;
    padding: 25px;
}

.profile_report_org_info {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
}

.profile_report_logo {
    margin-left: 0px !important;
    margin-right: 25px;
}

.profile_report_logo img {
    max-width: 150px!important;
    max-height: 120px;
}

.profile_report_detail {
    flex: 1;
}
.profile_report_title {
    display: flex;
    align-items: center;
}

.report_title {
    font-size: 30px;
    font-weight: 500;
    line-height: 1.2;
}

.profile_report_title .ui.button.btn_tertiary {
    margin-left: 15px !important;
}


.profile_report_title .ui.button:first-of-type {
    margin-left: 10px !important;
}

.profile_report_info {
    list-style: none;
    padding: 0;
    margin: 12px 0 0;
    display: flex;
    flex-wrap: wrap;
}

.profile_report_info li {
    margin-right: 30px;
}

.profile_report_info_th {
    font-size: 11px;
    font-weight: 500;
    color: #cfd3d5;
}

.profile_qrcode {
    line-height: 1;
}

.profile_report_logo {
    margin-left: 25px;
}

.profile_report_logo img {
    max-width: 200px;
}

.profile_report_card .detailsummary {
    display: flex;
    flex-direction: row;
    border-bottom: 1px dashed #ebebeb;
    background-color: #fafbfb;
    padding: 0 25px;
}

.profile_report_card .detailsummary_header {
    display: flex;
    flex-direction: column;
    padding-right: 24px;
    justify-content: center;
}

.profile_report_card .detailsummary_title {
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    padding-right: 4px;
    color: #5c6b70;
}

.profile_report_card .detailsummary_subtitle {
    font-size: 13px;
    font-weight: 400;
    color: #969fa2;
}

.profile_report_card .detailsummary_wrapper {
    display: flex;
    flex-direction: row;
}

.profile_report_card .detail_wrapper {
    padding: 12px 0;
}

.content-card-body {
    padding: 0 25px 25px;
}

.profile_wrapper {
    background-color: #ffffff;
    border: 1px solid #ebebeb;
    border-radius: 0;
}

.profile_content {
    padding-bottom: 25px;
}

.profile_list > div {
    padding-top: 30px;
}

.profile_header {
    justify-content: space-between;
    align-items: center;
    padding: 15px 25px;
    border-bottom: 1px solid #ebebeb;
}

.profile_header_left {
    display: flex;
    align-items: center;
}

.profile_header_title {
    font-size: 30px;
    font-weight: 700;
    line-height: 1.2;
}

.profile_header_subtitle {
    font-size: 14px;
    color: #7a7d7b;
    margin-top: 3px;
}

.profile_header_info {
    list-style: none;
    padding: 0;
    margin: 12px 0 0;
    display: flex;
}

.profile_header_info li {
    margin-right: 30px;
}

.profile_header_info_th {
    font-size: 11px;
    font-weight: 500;
    color: #cfd3d5;
}

.profile_header_detail_item {
    font-size: 14px;
    padding: 3px 0;
}

.profile_header_detail_item .icon {
    font-size: 16px;
    margin-right: 10px;
}

.profile_header_detail_item > img {
    width:18px;
    height: 18px;
    margin-right: 10px;
    display: inline-block;
    vertical-align: middle;
}

.profile_section {
    margin-top: 25px;
    padding: 0 20px;
}
.profile_section_header {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 8px;
}

.profile_section_body {
    border: 1px solid #ebebeb;
    background-color: #fafbfb;
}

.profile_section_body .tablewrapper {
    margin-bottom: 0;
}

.profile_section_body .tablewrapper .ui.table {
    margin-top: 0 !important;
}

.profile_section_body .tablewrapper .ui.table thead th {
    text-transform: uppercase;
}


.profile_section_body .tablewrapper .ui.table tr td {
    height: 31px;
}

.profile_section_body .tablewrapper .ui.table tr td img {
    width: 18px;
    height: 18px;
}

.profile_verification {
    display: flex;
}

.profile_verification img {
    margin-right: 3px;
}

.profile_onsite_status {
    display: flex;
    align-items: center;
}

.profile_onsite_status img {
    margin-right: 4px;
}

.remove_table_pointer_event tr {
    pointer-events: none !important;
}

@media (max-width: 1083px) {
    #dailychecksummary.summary_wrapper {
        padding-left: 0;
    }
}

@media (max-width: 991px) {
    #dailychecksummary.summary_wrapper {
        padding-right: 6px;
    }
    #credentialsummary.summary_wrapper {
        padding: 0 6px;
    }
    #onsitesummary.summary_wrapper {
        padding: 0 6px;
    }
    #credentialsummary .summary_icon,
    #dailychecksummary .summary_icon,
    #onsitesummary .summary_icon {
        margin-right: 10px;
    }

    #credentialsummary .summary_icon img,
    #dailychecksummary .summary_icon img,
    #onsitesummary .summary_icon img {
        width: 20px;
        height: 20px;
    }
    #dailychecksummary .summary_item,
    #onsitesummary .summary_item,
    #credentialsummary .summary_item {
        margin-right: 10px;
    }
}

@media (max-width: 767px) {
    .content-card-body {
        padding: 0 16px 16px;
    }
    .profile_report_header_top {
        padding: 16px;
    }
    .profile_report_info li {
        margin-right: 20px;
    }
    .profile_report_card .detailsummary {
        padding-left: 16px;
        padding-right: 16px;
    }
    .content-card-body {
        padding: 0 16px 16px;
    }
    .profile_section {
        padding: 0 16px 16px;
    }
    .profile_report_logo img {
        max-width: 150px!important;
        max-height: 120px;
    }
}

@media (max-width: 575px) {
    .profile_report_header_top {
        align-items: flex-start;
    }
    .profile_report_title{
        display: block;
    }
    .profile_report_title .ui.button.btn_tertiary {
        margin-left: 0 !important;
    }
    .profile_report_logo img {
        max-width: 150px!important;
        max-height: 100px;
    }
    .report_title {
        font-size: 24px;
    }
}

@media (max-width: 350px) {
    .profile_report_header_top {
        flex-direction: column;
    }

    .report_qrcode {
        margin-top: 1.5rem;
    }
}