.download_wrapper {
    display: grid;
    justify-items: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background-color: #F3F4F6;
}

.download_wrapper .download_content {
    width: 300px;
    border: 1px solid #eaeaea;
    padding: 28px;
    border-radius: 5px;
    background-color: #ffffff;
}

.download_wrapper .download_content img {
    padding-right: 10px;
}