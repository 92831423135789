
#dailychecksummary.summary_wrapper {
    display: flex;
    flex-direction: row;
    padding: 0 24px;
}

#dailychecksummary .summary_icon {
    margin-right: 16px;
    display: flex;
    align-items: center;
}

#dailychecksummary .summary_icon img {
    width: 24px;
    height: 24px;
}

#dailychecksummary .summary_item {
    margin-right: 16px;
}

#dailychecksummary .summary_item_number {
    font-size: 28px;
    font-weight: 600;
    letter-spacing: -1px;
    line-height: 26px;
    color: #8a8d8c;
}

#dailychecksummary .summary_item_text {
    font-size: 11px;
    font-weight: 500;
    color: #cfd3d5;
    line-height: 10px;
}

#dailychecksummary .summary_item_number.green {
    color: #69b536;
}

#dailychecksummary .summary_item_number.red {
    color: #e0454f;
}