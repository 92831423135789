
#crd_indv_all_credentials .content-filter {
    padding-top: 24px;
}

#crd_indv_all_credentials .content-card-content {
    padding: 16px;
}

#crd_indv_all_credentials .td_attachment img {
    width: 18px;
    height: 18px;
    margin: -1px 0 -3px 0;
}

#crd_indv_all_credentials .td_attachment {
    width: 34px;
}

#crd_indv_all_credentials .subtitle {
    font-size: 11px;
    font-weight: 500;
    color: #c6cecc;
    padding-bottom: 8px;
}

#crd_indv_all_credentials .checkresultswrapper {
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
}

#crd_indv_all_credentials .checkresult {
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    padding: 8px;
    margin-right: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 64px;
    margin-bottom: 4px;
}

#crd_indv_all_credentials .checkresult_text {
    padding-bottom: 8px;
    font-weight: 500;
    text-align: center;
}

#crd_indv_all_credentials .checkresult_icon {
    height: 32px;
    width: 32px;
}

#crd_indv_all_credentials .checkresult_icon img {
    height: 100%;
    width: 100%;
}

#crd_indv_all_credentials .tbl_icon {
    margin-right: 8px;
    margin-top: -1px;
}

#crd_indv_all_credentials .tbl_dailycheck {
    padding-left: 16px !important;
}

/*  media queries --------------------------------------------------------------------------------------------------- */

@media only screen and (max-width: 1280px) {

    #crd_indv_all_credentials .td_issued {
        display: none;
    }

    #crd_indv_all_credentials .td_expires {
        display: none;
    }

}

@media only screen and (max-width: 880px) {

    #crd_indv_all_credentials .td_issuedby {
        display: none;
    }

}

@media only screen and (max-width: 767px) {

    #crd_indv_all_credentials .tbl_row {
        margin-bottom: 0rem !important;
        margin-top: 0rem !important;
        padding-bottom: 13rem;
        border-top: 1px solid;
    }

    #crd_indv_all_credentials .tablewrapper {
        margin-top: 3rem;
        border-bottom: 1px solid;
    }

    #crd_indv_all_credentials .tbl_credentialicon {
        display: flex !important;
    }

    #crd_indv_all_credentials .table_header {
        display: none;
    }

    #crd_indv_all_credentials .td_individual {
        color: #29719a;
        text-decoration: underline;
    }

}