#tab_indv_wallet_credentials .mobile_tab_name {
    display: none;
}

#tab_indv_wallet_credentials .btn_primary {
    margin-right: 10px !important;
}

#tab_indv_wallet_credentials .tbl_credentialicon div {
    display: flex;
}

#tab_indv_wallet_credentials .td_icon_center {
    display: flex;
    justify-content: center;
    height: 31px;
}

#tab_indv_wallet_credentials .td_attachment img {
    width: 18px;
    height: 18px;
    margin: -1px 0 -3px 0;
}

#tab_indv_wallet_credentials .icon_history {
    width: 18px;
    height: 18px;
}

#tab_indv_wallet_credentials .td_attachment {
    width: 34px;
}

/*  media queries --------------------------------------------------------------------------------------------------- */

@media only screen and (max-width: 1270px) {

    #tab_indv_wallet_credentials .td_daysleft {
        display: none;
    }
}

@media only screen and (max-width: 1200px) {

    #tab_indv_wallet_credentials .td_expires {
        display: none;
    }
}

@media only screen and (max-width: 1120px) {

    #tab_indv_wallet_credentials .td_history {
        display: none;
    }
    
}

@media only screen and (max-width: 1060px) {

    #tab_indv_wallet_credentials .td_issued {
        display: none;
    }
}

@media only screen and (max-width: 800px) {

    #tab_indv_wallet_credentials .td_issuedby {
        display: none;
    }

    #tab_indv_wallet_credentials .td_credential {
        max-width: 11rem;
    }
}

@media only screen and (max-width: 700px) {

    #tab_indv_wallet_credentials .td_credential {
        max-width: 9rem;
    }

    #tab_indv_wallet_credentials .td_verification {
        display: none;
    }

}

@media only screen and (max-width: 500px) {

    #tab_indv_wallet_credentials .content-card-gridcontent{
        max-width: 20rem;
    }
    
    #tab_indv_wallet_credentials .content-filter {
        display: flex;
        flex-direction: column;
        padding: 5%;
    }

    #tab_indv_wallet_credentials .content-filter-archive {
        padding-bottom: 2rem;
    }

    #tab_indv_wallet_credentials .mobile_tab_name {
        padding-top: 5%;
        padding-bottom: 5%;
        display: block;
        text-align: center;
    }

    #tab_indv_wallet_credentials .item-pagination {
        align-content: center;
    }


}