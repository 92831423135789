#crd_indv_sharing_details .content-filter {
    padding-top: 0;
}

#crd_indv_sharing_details .td_share_link {
    cursor: pointer;
    width: 19px;
    height: 19px;
    margin-left: 8px;
}

#crd_indv_sharing_details .td_share_link img {
    width: 18px;
    height: 18px;
    margin: -1px 0 -3px 0;
}

#crd_indv_sharing_details .td_credentials {
    overflow-wrap: break-word;
    white-space: normal;
    max-width: 37.5rem;
}

/*  media queries --------------------------------------------------------------------------------------------------- */
