#mdl_indv_wallet_details_sharing_addlink {
    width: 400px;
}

#mdl_indv_wallet_details_sharing_addlink .modal-header-title {
    flex-grow: 1;
}
.ui .label{
    width: auto;
}


.ui.form .fields > .field.multiple,
.ui.form .fields > .field.location {
    width: 100%;
}

.dropdown_option {
    position: relative;
    padding-left: 40px;
}

.option_icon {
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -15px;
}

.option_title {
    font-weight: 700;
}

.option_meta_list {
    display: flex;
    margin-top: 6px;
}

.option_meta_list > div {
    font-size: 11px;
    color: rgba(0,0,0,0.6);
}

.option_meta_list > div + div {
    margin-left: 15px;
}

.option_meta_list > div > b {
    color: #cfd3d5;
}
