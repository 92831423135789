#mdl_pendingassignment {
    width: 575px;
    margin: 4px 8px;
}

#mdl_pendingassignment .modal-header-title {
    flex-grow: 1;
}

#mdl_pendingassignment .modal-content {
    padding: 0;
}

#mdl_pendingassignment .detail-item-status {
    cursor: pointer;
}

#mdl_pendingassignment input#reject_comments {
    width: 525px;
}