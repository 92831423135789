/*
Styles for the button to open the filter modal
*/
.filter_button {
    position: relative;
    width: 0;
    padding: 9px 19px !important;
}

.filter_button > i {
    margin-left: -6.5px !important;
}

div.filter_count_label {
    position: absolute;
    top: -5px;
    right: -5px;
    width: auto;
}


/*
Styles for within the filter modal
*/
div.modal.filter_modal .modal-header-close {
    margin-left: auto;
}

div.modal.filter_modal .modal-content {
    padding: 0;
}

div.modal.filter_modal .filter-content {
    position: relative;
    min-height: 140px;
}

div.modal.filter_modal .filter-content > div:not(:first-child) {
    border-top: 1px solid #00000066;
}

div.modal.filter_modal .filter_item_label {
    display: flex;
    height: 35px;
    padding: 8px;
    align-items: center;
}

div.modal.filter_modal .filter_item_label div:first-child {
    margin-top: 1px;
    margin-right: 6px;
}

div.modal.filter_modal .filter_item_label div:nth-child(3) {
    margin-left: auto;
}

div.modal.filter_modal div.filter_item_options > div {
    padding: 5px;
    height: 27px;
}

div.modal.filter_modal div.ui.checkbox label {
    color: #000000 !important;
    font-weight: normal;
}

div.modal.filter_modal .filter_search {
    height: 48px;
    padding: 4px;
}

div.modal.filter_modal div.filter_item_options > div:first-child {
    border-bottom: 1px dashed #00000066;
    height: 28px;
}

@media screen and (max-width: 500px) {
    div.modal.filter_modal div.filter_item .filter_item_option_container {
        transition: 0.5s ease height;
        overflow: hidden;
        background-color: #effafb;
    }
    div.modal.filter_modal div.filter_item .filter_item_options {
        overflow: auto;
    }
    div.modal.filter_modal div.filter_item:not(.open) .filter_item_option_container {
        height: 0 !important;
    }

    div.modal.filter_modal .filter_item_label_filler {
        display: none;
    }
}
@media screen and (min-width: 501px) {
    div.modal.filter_modal div.filter-content {
        background-color: #effafb;
    }

    div.modal.filter_modal .filter_item_label {
        border-right: 1px solid #00000066;
        background-color: #ffffff;
    }

    div.modal.filter_modal div.filter_item:not(.open) .filter_item_option_container {
        display: none;
    }

    div.modal.filter_modal div.filter_item .filter_item_options {
        overflow: auto;
        position: absolute;
        top: 48px;
        bottom: 0;
        left: 0;
        right: 0;
        height: auto !important;
    }

    div.modal.filter_modal div.filter-content > div {
        width: 50%;
    }

    div.modal.filter_modal div.filter_item.open div.filter_item_option_container {
        position: absolute;
        top: 0;
        right: 0;
        width: 50%;
        height: 100% !important;
    }
}

div.modal.filter_modal .filter_cancel_button {
    display: flex;
    justify-content: right;
    justify-content: flex-start;
    padding: 15px;
    border-top: 1px solid #00000066;
}