#mdl_acknowledge_document {
    width: 535px;
}

#mdl_docviewer .modal-footer-page-control {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

#mdl_docviewer .img-load-error {
    display: flex;
}

#mdl_docviewer .modal-footer-page-tools {
    display: flex;
    flex-direction: row-reverse;
    justify-content: right;
}

#mdl_docviewer .modal-footer-archive {
    width: 100%;
    display: flex;
    flex-direction: column;
}

#mdl_docviewer .modal-footer {
    height: 8rem;
    flex-direction: column;
}

#mdl_docviewer .img-footer {
    height: 4rem;
    flex-direction: column;
}

#mdl_docviewer .modal-footer-buttons {
    padding-bottom: 8px;
}

#mdl_docviewer {
    width: 94%;
}

#mdl_docviewer .modal-header-title {
    flex-grow: 1;
}

#mdl_docviewer .modal-content {
    overflow: hidden;
    position: relative;
}

#mdl_docviewer .pdf-container {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
}

#mdl_docviewer .pdf-bookmarks {
    border-right: none;
    transition: width 400ms ease-in-out;
    width: 0%;
    white-space: nowrap;
    overflow: auto;
}
#mdl_docviewer .pdf-bookmarks.open {
    border-right: 1px solid rgba(0, 0, 0, 0.3);
    width: 20%;
}
.rpv-bookmark__container, .rpv-bookmark__list {
    overflow: visible !important;
}
#mdl_docviewer .pdf-viewer {
    flex: 1;
    transition: width 400ms ease-in-out;
    width: 100%;
}
#mdl_docviewer .pdf-viewer.bookmarks-open {
    width: 80%;
}
@media (max-width: 800px) {
    #mdl_docviewer .pdf-bookmarks {
        position: absolute;
        height: 100%;
        z-index: 1;
        background-color: #ffffff;
    }
    #mdl_docviewer .pdf-bookmarks.open {
        width: 45%;
    }
    #mdl_docviewer .pdf-viewer.bookmarks-open {
        width: 100%;
    }
}

#mdl_docviewer .btn_tertiary img {
    width: 18px;
    height: 18px;
    margin: -1px 4px -3px 0;
}

#mdl_docviewer i.icon {
    font-size: 1.4em;
    opacity: 0.65;
    margin: -1px 4px -3px 0;
}

#mdl_docviewer .next_page i.icon {
    margin: 0 -5px -3px 0;
}

#mdl_docviewer .previous_page i.icon {
    margin: 3px 0px 0px -5px;
}

#mdl_docviewer .btn_tertiary label {
    font-size: 11px !important;
    font-weight: 700 !important;
    color: #29719a !important;
    padding-top: 3px;
    cursor: pointer;
}

#mdl_docviewer .modal-footer-archive p,
#mdl_docviewer .previous_page,
#mdl_docviewer .modal-footer-archive .rpv-page-navigation__current-page-input input,
#mdl_docviewer .next_page {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: left;
    background-color: transparent;
    font-size: 11px;
    font-weight: 700;
    color: #0f5d86;
    border: none;
    padding: 20px 16px 8px 0;
    width: -moz-fit-content;
    width: fit-content;
    outline-width: 0;
}

#mdl_docviewer .zoom_in {
    color: #0f5d86;
}

#mdl_docviewer .zoom_out {
    color: #0f5d86;
}

#mdl_docviewer .docviewer_rotate {
    color: #0f5d86;
}

#mdl_docviewer .previous_page {
    margin: 16px 9px 0px 0px !important;
    padding: 1px 0px 2px 0px !important;
    width: 20px;
    height: 22px;
    border: 1px solid #29719a;
    border-radius: 3px 0px 0px 3px !important;
    padding-bottom: 3px !important;
}

#mdl_docviewer .modal-footer-archive .rpv-page-navigation__current-page-input input {
    border: 1px solid #29719a !important;
    border-radius: 0px !important;
    text-align: center;
    margin: 16px 5px 0px -10px !important;
    width: 30px;
    height: 22px;
    padding: 1px 0px 0px 0px !important;
}

#mdl_docviewer .rpv-page-navigation__current-page-input {
    margin: 0;
    width: 25px;
}

#mdl_docviewer .next_page {
    border: 1px solid #29719a;
    border-radius: 0px 3px 3px 0px !important;
    margin: 16px 14px 0px -6px !important;
    padding: 1px 6px 2px 0px !important;
    width: 20px;
    height: 22px;
    padding-bottom: 3px !important;
}

#mdl_docviewer .modal-footer-archive input::-webkit-outer-spin-button,
#mdl_docviewer .modal-footer-archive input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

#mdl_docviewer .modal-footer-archive input[type=number] {
    -moz-appearance: textfield;
}

#mdl_docviewer .modal-footer-archive div.modal-footer-page-tools div:not(.modal-footer-buttons) > button {
    height: 41px;
}

.rpv-core__arrow.rpv-core__arrow--bl.rpv-core__popover-body-arrow {
    transform: translate(50%,-50%) rotate(45deg);
    top: unset;
    bottom: -11px;
}

#rpv-core__popover-body-search.rpv-core__popover-body {
    margin-top: -170px;
}

button.rpv-core__button {
    color: #3c7ea3;
    background-color: #ffffff;
    border: 1px solid #e5e7e8;
    font-weight: 700;
    text-transform: uppercase;
    font-family: "Open Sans", sans-serif;
    font-size: 11px;
}

#mdl_docviewer .docviewer_error {
    height: 65vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center
}

#mdl_docviewer #image_preview {
    margin: 0;
    text-align: center;
    vertical-align: middle;
    overflow: auto;
    /*height: 69vh;*/
}

#mdl_docviewer #image_preview img {
    transition: transform .2s;
    margin-top:3vh;
}

#imgPreviewContainer {
    height: 69vh;
    /*display: flex;*/
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: auto;
}

#mdl_docviewer .contain {
    object-fit: contain;
}

#mdl_docviewer .pdf_preview {
    height: 69vh;
    overflow: auto;
    align-items: center;
}

#mdl_docviewer .pdf_document {
    display: flex;
    flex-direction: column;
}

#mdl_docviewer .pdf_page {
    border: 1px solid darkgrey;
    box-shadow: 5px 5px 5px 1px #ccc;
    border-radius: 5px;
    margin: auto;
}

@media (max-width: 800px) {
    #mdl_docviewer .ui.button.btn_tertiary {
        padding-right: 4px;
    }

    #mdl_docviewer .next_page {
        margin-right: 6px !important;
    }

    #mdl_docviewer .modal-footer-archive p {
        padding-right: 10px;
    }
}

@media (max-width: 730px) {

    #mdl_docviewer .btn_tertiary img {
        margin: 0px;
    }

    #mdl_docviewer .next_page {
        padding-bottom: 2px !important;
    }

    #mdl_docviewer .previous_page {
        padding-bottom: 2px !important;
    }

    #mdl_docviewer .modal-header-title {
        word-break: break-all;
        max-width: 95%;    }

    #mdl_docviewer .modal-header {
        height: 5rem;
    }
}

@media (max-width: 494px) {
    #mdl_docviewer .ui.button.btn_tertiary {
        padding-right: 0px;
    }

    #mdl_docviewer .modal-header-title {
        padding-right: 0px;
    }

    #mdl_docviewer .modal-header-close {
        padding-left: 8px;
        padding-right: 8px;
    }

    #mdl_docviewer .btn_tertiary label {
        display: none;
    }

    #mdl_docviewer .btn_tertiary {
        padding: 0;
    }

    #mdl_docviewer .modal-footer-archive p {
        padding-right: 5px;
        max-width: 54px;
        min-width: 44px;
    }

    #mdl_docviewer .next_page {
        margin-right: 0px !important;
    }

    #mdl_docviewer .modal-footer {
        height: 7rem;
    }
}

@media (max-width: 480px) {
    #mdl_docviewer .modal-footer {
        justify-content: center;
        height: 6.5rem;
    }

    #mdl_docviewer .img-footer {
        height: 3rem;
    }

    #mdl_docviewer .modal-footer .modal-footer-archive {
        padding-left: 8px;
    }

    #mdl_docviewer .modal-footer-buttons .ui.button {
        padding: 10px;
    }

    #mdl_docviewer .btn_tertiary {
        padding-left: 1rem;
    }

}

@media (max-width: 360px) {
    #mdl_docviewer i.icon {
        margin-right: 0px;
    }

    #mdl_docviewer .modal-footer .modal-footer-archive {
        padding-left: 4px;
    }

    #mdl_docviewer .modal-footer .modal-footer-buttons {
        padding-left: 0px;
        padding-right: 4px;
    }

    #mdl_docviewer .modal-footer-archive .rpv-page-navigation__current-page-input input {
        width: 24px;
    }

    #mdl_docviewer .docviewer_rotate {
        display: none;
    }
}
