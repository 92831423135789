
#cw_indv_overview .profilewrapper {
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
}

.orgalertswrapper {
    flex-grow: 1;
}

.alertswrapper {
    display: flex;
    flex-direction: row;
}

@media only screen and (max-width: 500px) {

    .content-tabs-item {
        max-width: 4.7rem;
        max-height: 3rem;
        font-size: 0 !important;
        font-weight: 0 !important;
    }

}