.profile_section_body .td_loc_icon_center {
    width: 120px !important;
}

/*  media queries --------------------------------------------------------------------------------------------------- */

@media only screen and (max-width: 1200px) {

}

@media only screen and (max-width: 1150px) {

}

@media only screen and (max-width: 1060px) {

}

@media only screen and (max-width: 700px) {

    .profile_section_body .td_mgr_ovrd {
        display: none;
    }

}

@media only screen and (max-width: 530px) {

    .profile_section_body .td_issued {
        display: none;
    }

}

@media only screen and (max-width: 420px) {

    .profile_section_body .td_loc_icon_center {
        width: 100px !important;
    }

}