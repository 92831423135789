
#crd_indv_dailycheck {
    max-width: 1000px;
}

#crd_indv_dailycheck .content-filter {
    justify-content: flex-start;
}

#crd_indv_dailycheck .templates {
    width: 300px;
}

#crd_indv_dailycheck .dailycheck-question {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
    background-color: #f8f8f9;
    border-radius: 4px;
}

#crd_indv_dailycheck .dailycheck-question-wrapper {
    padding-right: 24px;
    width: calc(100% - 100px);
}

.dailycheck-question-category {
    font-size: 11px;
    font-weight: 500;
    color: #a1a3a3;
}

#crd_indv_dailycheck .dailycheck-question-answer {
    display: flex;
    flex-direction: row;
}

#crd_indv_dailycheck .dailycheck-question-answer div {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    border: 1px solid #d5d5d5;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    line-height: 16px;
    cursor: pointer;
    margin-left: 8px;
}

#crd_indv_dailycheck .answer_active {
    background-color: #29719a;
    color: #fff;
    border-color: #29719a !important;
}

#crd_indv_dailycheck .certify {
    font-weight: 500;
    text-align: center;
    margin-top: 32px;
    margin-bottom: 20px;
}

#crd_indv_dailycheck .footer {
    text-align: left;
    margin-bottom: 20px;
}

#crd_indv_dailycheck .privacypolicy {
    display: flex;
    text-align: center;
}

#crd_indv_dailycheck .privacypolicy-item {
    border: 1px solid #e5e7e8;
    border-radius: 3px;
    padding: 8px 16px;
    overflow: hidden;
    width: 100%;
    margin: 0 4px 0 4px;
    color: #8a8d8c;
}

/*  onsite ---------------------------------------------------------------------------------------------------------- */

#crd_indv_dailycheck .onsite {
    display: flex;
    flex-direction: row;
    border-top: 1px dashed #ebebeb;
    border-bottom: 1px dashed #ebebeb;
    background-color: #fafbfb;
    padding: 0 24px 0 16px;
    margin: 24px -24px 0 -24px;
    height: 71px;
}

#crd_indv_dailycheck .onsite_header {
    display: flex;
    flex-direction: column;
    padding-right: 24px;
    justify-content: center;
}

#crd_indv_dailycheck .onsite_title {
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    padding-right: 4px;
    color: #5c6b70;
}

#crd_indv_dailycheck .onsite_subtitle {
    font-size: 13px;
    font-weight: 400;
    color: #969fa2;
}

#crd_indv_dailycheck .message {
    margin: 0 24px 24px 24px;
}

.onsite_content {
    display: flex;
    flex-direction: column;
    padding-right: 24px;
    justify-content: center;
}


@media only screen and (max-width: 650px) {

    #crd_indv_dailycheck .privacypolicy {
        flex-direction: column;
    }

    #crd_indv_dailycheck .privacypolicy-item {
        margin: 0 0 8px 0;
    }
}
