#mdl_indv_dailychecks .td_override_spacer {
    padding-top: 13px;
}

#mdl_indv_dailychecks {
    width: 1100px !important;
}

#mdl_indv_dailychecks .modal-content {
    padding: 0;
}

#mdl_indv_dailychecks .content-filter {
    padding-top: 16px;
}

/*  media queries --------------------------------------------------------------------------------------------------- */

@media only screen and (max-width: 1160px) {

    #mdl_indv_dailychecks {
        width: 850px !important;
    }
}

@media only screen and (max-width: 880px) {

    #mdl_indv_dailychecks {
        width: 700px !important;
    }
}