
#org-locations_mdl_manageforms {
    width: 900px !important;
}

#org-locations_mdl_manageforms .modal-content {
    padding: 0;
}

#org-locations_mdl_manageforms .content-filter {
    padding-top: 16px;
}