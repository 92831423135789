
#sta_indv_overview_form {
  width: 200px;
  padding: 0;
  display: flex;
  margin-bottom: 4px;
}

#sta_indv_overview_form .form-bg  {
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  flex: 1;
  align-items: center;
  cursor: pointer;
  padding-bottom: 24px;
}

#sta_indv_overview_form .form-alert {
  background-color: #e0454f;
}

#sta_indv_overview_form .form-none {
  background-color: #69b536;
}

#sta_indv_overview_form .form-icon {
  margin: 16px 0;
}

#sta_indv_overview_form .form-icon img {
  width: 48px;
  height: 48px;
  margin-top: 3px;
}


#sta_indv_overview_form .form-text-wrapper {
  text-align: center;
}

#sta_indv_overview_form .form-title {
  font-size: 14px;
  font-weight: 700;
  line-height: 15px;
  color: #ffffff;
  padding: 0 8px;
}

#sta_indv_overview_form .form-subtitle {
  font-size: 13px;
  font-weight: 500;
  line-height: 15px;
  color: #ffffff;
  margin: 24px 0 4px;
  padding: 0 8px;
  text-align: center;
}

@media only screen and (max-width: 700px) {

  #sta_indv_overview_form {
      width: 100%;
      margin-right: 0;
  }

  #sta_indv_overview_form .form-bg {
      flex-direction: row;
      padding-bottom: 0;
  }


  #sta_indv_overview_form .form-icon {
      margin: 16px 0 16px 16px;
  }

  #sta_indv_overview_form .form-text-wrapper {
      text-align: left;
      padding-left: 8px;
  }

  #sta_indv_overview_form .form-subtitle {
      margin-top: 8px;
      margin-bottom: 0;
  }
}