
#org-credentials_mdl_bulkinvite {
    width: 400px !important;
}

#org-credentials_mdl_bulkinvite .modal-header-title {
    flex-grow: 1;
}

#org-credentials_mdl_bulkinvite #file {
    width: 100%;
    display: flex;
    flex-direction: column;
}

#org-credentials_mdl_bulkinvite #fileinput {
    margin-bottom: 8px;
}

#org-credentials_mdl_bulkinvite .btn_fileupload {
    width: 100%;
}