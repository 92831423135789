
#mdl_indv_wallet_details {
     width: 400px;
 }

#mdl_indv_wallet_details #firstname {
    width: 50% !important;
    margin-right: 4px;
}

#mdl_indv_wallet_details .lastname {
    width: 50% !important;
}

#mdl_indv_wallet_details #phone {
    width: 50% !important;
    margin-right: 4px;
}

#mdl_indv_wallet_details .default_language {
    width: 50% !important;

}

#mdl_indv_wallet_details .city {
    width: calc(100% - 10px) !important;
}

#mdl_indv_wallet_details #city {
    margin-right: 4px;
}

#mdl_indv_wallet_details #province {
    width: 135px !important;
}